import * as Text from '@commoncomponents/frontend-components/dist/components/Text';

type NoResultsProps = {
  text?: string | JSX.Element;
};

export const NoResults = (props: NoResultsProps) => {
  const { text = 'No results!' } = props;
  return <Text.Body className="color-dark weight-bold">{text}</Text.Body>;
};
