import React from 'react';
import styled from 'styled-components';
import Loader from '@commoncomponents/frontend-components/dist/components/Loader';

type PropsType = {
  customHeight?: string;
  dataTestId?: string;
};

export const LoaderWithOverlay = ({ customHeight, dataTestId }: PropsType) => (
  <Overlay customHeight={customHeight} data-testid={dataTestId}>
    <Loader active={true} />
  </Overlay>
);

const Overlay = styled.div<{ customHeight?: string }>`
  background-color: rgb(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  height: ${({ customHeight }) => customHeight || '100%'};
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1201;
`;
