import { FormattedMessage } from 'react-intl';
import { IconAdd } from '@commoncomponents/frontend-components/dist/components/Icons/Icons';
import { colors } from '@commoncomponents/frontend-components/dist/styled';
import * as Button from '@commoncomponents/frontend-components/dist/components/Buttons';
import { useIsMobile } from '../utils/useIsMobile';

type AddUserButtonProps = {
    onClick?: () => void;
};

export const AddUserButton = (props: AddUserButtonProps) => {
    const isMobile = useIsMobile();

    return (
        <Button.SecondaryRounded
            onClick={props.onClick}
            startIcon={<IconAdd color={colors.rockyGrey} />}
            label={
                <FormattedMessage
                    id="MSMarketplace.selectUsers"
                    defaultMessage="Wybierz użytkowników"
                />
            }
            size={'MEDIUM'}
            fullWidth={isMobile}
        />
    );
};
