import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { AccountSettingsBaseRouteUrl } from '../constants';

const useNavigationMenuList = () => {
  const menuList = useMemo(
    () => [
      {
        sectionName: (
          <FormattedMessage
            id="NavigationMenuList.CertificatesLink"
            defaultMessage="Certyfikaty"
          />
        ),
        sectionID: 'accounts',
        path: `${AccountSettingsBaseRouteUrl}/certificates`,
        elementID: 'Certificates',
        id: 'top_navigation-certificates-link',
      },
      {
        sectionName: (
          <FormattedMessage
            id="NavigationMenuList.SettingsLink"
            defaultMessage="Ustawienia"
          />
        ),
        sectionID: 'accounts',
        path: `${AccountSettingsBaseRouteUrl}/general`,
        elementID: 'Settings',
        id: 'top_navigation-settings-link',
      },
    ],
    [],
  );

  return menuList;
};

export default useNavigationMenuList;
