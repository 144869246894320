import React, { useState, useLayoutEffect, useEffect } from 'react';
import * as Modal from '@commoncomponents/frontend-components/dist/components/Modal';
import { FormattedMessage } from 'react-intl';
import SelectUsersTable, { User } from './SelectUsersTable';
import { useUsersContext } from '../contexts/UsersContext';
import * as Button from '@commoncomponents/frontend-components/dist/components/Buttons';
import Search from '@commoncomponents/frontend-components/dist/components/Search';
import { SearchDiv, ShowMoreLink, ShowMoreWrapper } from './styled';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    selectedUsers: { [key: string | number]: boolean };
    usersQuantity: number;
    onChange: (event: React.ChangeEvent<HTMLInputElement>, user: User) => void;
    onRowTap: (
        event: React.MouseEvent<HTMLTableRowElement>,
        user: User
    ) => void;
    onSubmit: () => void;
    organizationUsers: Object;
};



const SelectUsersModal = ({
    isOpen,
    onClose,
    selectedUsers,
    usersQuantity,
    onChange,
    onRowTap,
    onSubmit,
    organizationUsers,
}: Props) => {
    const { state, fetchUsers, fetchMoreUsers } = useUsersContext();
    const searchValue = state?.search;
    const [search, setSearch] = useState<string>(searchValue);
    const [users, setUsers] = useState<Array<User>>(state?.users);
    const [usersFiltered, setUsersFiltered] = useState<Array<User>>(users);
    const [isTableLoading, setIsTableLoading] = useState<boolean>(false);

    const handleOnSearchChange = (value: string) => {
        value = value.trim();
        setIsTableLoading(true);
        setSearch(value);
        setUsersFiltered(
            users.filter(
                (element) =>
                    element.name.includes(value) ||
                    element.surname.includes(value) ||
                    `${element.name.toLowerCase()} ${element.surname.toLowerCase()}`.includes(
                        value.toLowerCase()
                    ) ||
                    element.email.includes(value)
            )
        );
        fetchUsers({
            size: state.size,
            page: 0,
            count: state.count,
            search: value,
        }).then(() => setIsTableLoading(false));
    };

    function handleShowMoreClick() {
        setIsTableLoading(true);
        fetchMoreUsers({
            size: state.size,
            page: state.page,
            count: state.count,
            search: search,
        }).then(() => setIsTableLoading(false));
    }

    useLayoutEffect(() => {
        const users = state?.users || [];
        users.forEach(
            (user) =>
                (user.plan =
                    organizationUsers['content']
                        .find((u) => u.id === user.id)
                        ?.planSubscription?.name.split(' ')[1] || 'Free')
        );
        users.filter((user) => user.status === 'ACTIVE')
        setUsers(users);
        setUsersFiltered(users);
    }, [state?.users]);


    const [isUserListComplete, setIsUserListComplete] = useState<boolean | undefined>(undefined);
    useEffect(() => {
      state?.users?.length >= state?.count ? setIsUserListComplete(true) : setIsUserListComplete(false);
    }, [state]);

    return (
        <Modal.Container open={isOpen} onClose={onClose}>
            <Modal.Title
                title={
                    <FormattedMessage
                        id="MSMarketplace.chooseUsers"
                        defaultMessage="Wybierz użytkowników"
                    />
                }
                children={
                    <>
                        <SearchDiv>
                            <Search
                                onChange={handleOnSearchChange}
                                value={search}
                                setOnUserInteraction={false}
                                debounceMs={500}
                                placeholder={
                                    <FormattedMessage
                                        id="Global.NameSurnameOrEmail"
                                        defaultMessage="Imię, nazwisko lub e-mail"
                                    />
                                }
                            />
                        </SearchDiv>
                        <FormattedMessage
                            id="MSMarketplace.chosenUsers"
                            defaultMessage="Wybranych użytkowników: "
                        />
                        <span style={{ fontWeight: 'bold' }}>
                            {
                                Object.keys(selectedUsers).filter(
                                    (u) => selectedUsers[u]
                                ).length
                            }
                            /{usersQuantity}
                        </span>
                    </>
                }
            />

            <Modal.Content>
                <>
                    <SelectUsersTable
                        users={usersFiltered}
                        usersQuantity={usersQuantity}
                        onChange={onChange}
                        onRowTap={onRowTap}
                        isChecked={selectedUsers}
                        isLoading={isTableLoading}
                    />
                    {!isUserListComplete && (
                        <ShowMoreWrapper>
                            <ShowMoreLink onClick={handleShowMoreClick}>
                                <FormattedMessage
                                    id="MSMarketplace.showMore"
                                    defaultMessage="Pokaż więcej"
                                />
                            </ShowMoreLink>
                        </ShowMoreWrapper>
                    )}
                </>
            </Modal.Content>
            <Modal.Buttons>
                <Button.GhostRounded
                    label={
                        <FormattedMessage
                            id="MSMarketplace.Abort"
                            defaultMessage="Anuluj"
                        />
                    }
                    onClick={onClose}
                />
                <Button.PrimaryRounded
                    label={
                        <FormattedMessage
                            id="MSMarketplace.AssignPlans"
                            defaultMessage="Przypisz plany"
                        />
                    }
                    onClick={() => {
                        onSubmit();
                        onClose();
                    }}
                />
            </Modal.Buttons>
        </Modal.Container>
    );
};

export default SelectUsersModal;
