import { useRef } from 'react';
import styled from 'styled-components/macro';
import { colors } from '@commoncomponents/frontend-components/dist/styled';

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  position: relative;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div<{ checked: boolean; disabled?: boolean }>`
  width: 20px;
  height: 20px;
  background: ${(props) =>
    props.disabled
      ? props.checked
        ? colors.floppyAqua
        : colors.airlessPlatinum
      : props.checked
      ? colors.floppyAqua
      : colors.snowy};
  border-radius: 4px;
  border: 1px solid
    ${(props) => (props.checked ? colors.floppyAqua : colors.stressfulSnow)};
  transition: all 150ms;

  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }

  ${(props) => props.disabled && props.checked && 'opacity: 0.5;'}
`;

type Props = React.ComponentProps<typeof HiddenCheckbox>;

export const Checkbox = (props: Props) => {
  const ref = useRef<HTMLInputElement>();

  const handleOnChange = () => {
    if (!props.disabled && props.onChange)
      props.onChange({ target: { checked: !ref.current?.checked } });
  };

  return (
    <CheckboxContainer>
      <HiddenCheckbox
        {...props}
        checked={props.checked}
        disabled={props.disabled}
        ref={ref}
      />
      <StyledCheckbox
        checked={props.checked}
        disabled={props.disabled}
        onClick={handleOnChange}
      >
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  );
};
