import {
  AvatarWrapper,
  AvatarCircleDiv,
  AvatarSpan,
  AvatarTextsDiv,
  NameSurnameSpan,
  EmailSpan,
} from './styled';

type Props = {
  className?: string;
  name: string;
  surname: string;
  email?: string;
  nameAndSurnameBold?: boolean;
  size?: number;
  showTexts?: boolean;
  formatName?: (value: string) => string | JSX.Element;
  testerProps?: {
    avatarDataTestId?: string;
    nameDataTestId?: string;
    emailDataTestId?: string;
  };
};

export const Avatar = (props: Props) => {
  const {
    className,
    name,
    surname,
    email,
    nameAndSurnameBold,
    size = 32,
    showTexts = true,
    formatName,
    testerProps,
  } = props;

  const nameAndSurname = `${name || ''} ${surname || ''}`;

  return (
    <AvatarWrapper className={className}>
      <AvatarCircleDiv size={size} data-testid={testerProps?.avatarDataTestId}>
        <AvatarSpan size={size}>
          {name[0]}
          {surname[0]}
        </AvatarSpan>
      </AvatarCircleDiv>
      {showTexts && (
        <AvatarTextsDiv>
          <NameSurnameSpan
            bold={nameAndSurnameBold}
            data-testid={testerProps?.nameDataTestId}
          >
            {formatName ? formatName(nameAndSurname) : nameAndSurname}
          </NameSurnameSpan>
          {email && (
            <EmailSpan data-testid={testerProps?.emailDataTestId}>
              {email}
            </EmailSpan>
          )}
        </AvatarTextsDiv>
      )}
    </AvatarWrapper>
  );
};
