import {PureComponent, Fragment} from 'react';
import styled from "styled-components";
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {helpers, ducks} from 'autenti-components';
import {connect} from "react-redux";
import {bindActionCreators} from "@reduxjs/toolkit";
import MicrosoftSaasOffers from "./MicrosoftSaasOffers";
import TopNavigationComponent from "../components/TopNavigationComponent";
import {Login} from "./Login";
import {actions as organizationUsersActions} from '../ducks/organizationUsers';
import Confirmation from './MicrosoftSaasOffers/Confirmation';
import Error from './MicrosoftSaasOffers/Messages/Error';
import OfferCompletedMessage from './MicrosoftSaasOffers/Messages/OfferCompleted';
import StepperWrapper from '../components/StepperWrapper/StepperWrapper';
import {State} from "../ducks";
import RedirectToAccounts from "./RedirectToAccounts";
import {getSubscriptionStatusSelector} from "../selectors/getSubscriptionStatusSelector";

// import {getUpdateBrowserBannerData} from "@autenti/utils/dist/helpers";

interface PropsType {
    currentUser: Object | null;
    language: string;
    showSnackbar: boolean;
    snackbarBody: string;
    snackbarVariant: 'ERROR' | 'INFO' | 'SUCCESS' | 'WARNING';
    SNACKBAR_ACTIONS: { TOGGLE_OFF: () => void };
    ORGANIZATION_PAYMENT_ACTIONS: {
        CHANGE_UPGRADE_MODAL_VISIBILITY: () => void;
    };
    isUpgradeModalVisible: boolean;
    ENTITLEMENTS_ACTIONS: {
        REQUEST_ENTITLEMENTS: (type: string) => void;
    };
    ORGANIZATION_USERS_ACTIONS: {
        REQUEST: (searchValue?: any) => void;
    };
};

type StateType = {
    showUpgradeBrowserBanner: boolean | string;
    offerCompleted: boolean;
};

export const AppWrapper = styled.div<{
    showUpgradeBrowserBanner?: boolean;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  background-color: ${(props) => props.theme.vLightGray};

  select:focus,
  textarea {
    font-size: ${(props) => props.theme.fontMedium};
  }

  ${({showUpgradeBrowserBanner}) =>
          showUpgradeBrowserBanner &&
          ` 
    padding-top: 68px;
    @media(min-width: 1024px){
      padding-top: 56px;
    }
    .top_navigation-wrapper{
      margin-top: 68px;
      @media(min-width: 1024px){
        margin-top: 56px;
      }
    }
    .left-menu{
      padding-top: 56px;
    }
  `} @media ${(props) => props.theme.md} {
    select:focus,
    textarea {
      font-size: ${(props) => props.theme.fontSmall};
    }
  }
`;

export class Scenes extends PureComponent<PropsType, StateType> {
    state = {
        showUpgradeBrowserBanner: '',
        offerCompleted: false
    };

    // componentDidMount() {
    //     // console.log('tu')
    //     const {
    //         ENTITLEMENTS_ACTIONS: {REQUEST_ENTITLEMENTS},
    //     } = this.props;
    //     REQUEST_ENTITLEMENTS('BPA_GUI');
    //     setTimeout(() => helpers.registerInlineManualEvents(), 1500);
    //     getSubscriptionStatusSelector().then(data => {
    //         this.setState({
    //             offerCompleted: data
    //         });
    //     });
    // }

    render() {
        const {
            language,
            snackbarBody,
            snackbarVariant,
            showSnackbar,
            SNACKBAR_ACTIONS: {TOGGLE_OFF},
            ORGANIZATION_PAYMENT_ACTIONS: {CHANGE_UPGRADE_MODAL_VISIBILITY},
            isUpgradeModalVisible,
            ORGANIZATION_USERS_ACTIONS: {REQUEST},
        } = this.props;
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(decodeURI(queryString));
        const offerToken: string = urlParams.get("token") || "";
        const decodedToken = decodeURIComponent((offerToken).replace(/\+/g, '%2B'))
        offerToken && localStorage.setItem('offer-token', encodeURIComponent(decodedToken));
        const displayOfferCompleted = Boolean(this.state.offerCompleted);
        return (
            <AppWrapper className="app">
                <Fragment>
                    <TopNavigationComponent location={'top'} navigationVariant={''} />
                    {!displayOfferCompleted ?
                        <BrowserRouter>
                            <StepperWrapper/>
                            <Routes>
                                <Route path="/">
                                    <Route index element={<Login/>}/>
                                </Route>
                                <Route path="step2" element={helpers.hasLoginCookie() ?
                                    <MicrosoftSaasOffers/>
                                    :
                                    <RedirectToAccounts/>}>

                                </Route>
                                <Route path="step3" element={helpers.hasLoginCookie() ?
                                    <Confirmation/>
                                    :
                                    <RedirectToAccounts/>}>
                                </Route>
                                <Route path="error">
                                    <Route index element={<Error/>}/>
                                </Route>
                            </Routes>
                        </BrowserRouter>
                        : <OfferCompletedMessage/>
                    }
                </Fragment>
            </AppWrapper>
        );
    }
}

export const mapStateToProps = (state: State) => {
    const currentUser =
        state.naturalUser.get('data') || state.organizationUser.get('data');
    const language = state.lang.get('lang');
    const showSnackbar = state.globalSnackbar.get('active');
    const snackbarBody = state.globalSnackbar.get('body');
    const snackbarVariant = state.globalSnackbar.get('variant');
    const isUpgradeModalVisible = state.organizationPayment.get(
        'upgradeModalVisible',
    );
    return {
        currentUser,
        language,
        showSnackbar,
        snackbarBody,
        snackbarVariant,
        isUpgradeModalVisible,
    };
};

export const mapDispatchToProps = (dispatch: any) => ({
    SNACKBAR_ACTIONS: bindActionCreators(ducks.SNACKBAR_ACTIONS, dispatch),
    ORGANIZATION_PAYMENT_ACTIONS: bindActionCreators(
        ducks.ORGANIZATION_PAYMENT_ACTIONS,
        dispatch,
    ),
    ENTITLEMENTS_ACTIONS: bindActionCreators(
        ducks.ENTITLEMENTS_ACTIONS,
        dispatch,
    ),
    ORGANIZATION_USERS_ACTIONS: bindActionCreators(
        organizationUsersActions,
        dispatch,
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Scenes);
