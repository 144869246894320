import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import { Route, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from '../Header';
import TopNavigationComponent from '../TopNavigationComponent';
// import BottomNavigation from '../BottomNavigation';

type PropsType = {
  showDefaultHeader?: boolean;
  showTopMenu?: boolean;
  isMobileView?: boolean | null;
  children: ReactNode;
  pageTitle?: string;
  fullWidth?: boolean;
};

type HeightStyles = {
  headerPadding?: boolean;
};

type ContainerStyles = {
  fullWidth?: boolean;
};


const ContainerWrapper = styled.div<ContainerStyles>`
  flex: 1 1 auto;
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;
  height: 100%;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
`;

const HeightWrapper = styled.div<HeightStyles>`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
`;

const BottomNavigationWrapper = styled.div`
  & > div:first-of-type {
    flex: 0 0 auto;
    position: relative;
  }
`;

const SceneWrapper = ({
  showDefaultHeader,
  showTopMenu,
  isMobileView,
  pageTitle,
  children,
  fullWidth,
}: PropsType) => {
  return (
    <>
      {pageTitle && (
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
      )}
      {showDefaultHeader && <Header />}
      <ContainerWrapper>
        <HeightWrapper
          headerPadding={showDefaultHeader || showTopMenu}
          className="height-wrapper"
        >
          {children}
        </HeightWrapper>
      </ContainerWrapper>
    </>
  );
};

SceneWrapper.defaultProps = {
  showDefaultHeader: false,
  showTopMenu: false,
  fullWidth: false,
};

export default SceneWrapper;
