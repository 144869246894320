import { colors } from '@commoncomponents/frontend-components/dist/styled';
import {
    IconPlanFree,
    IconPlanLite,
    IconPlanPro,
    IconPlanPremium,
} from '@commoncomponents/frontend-components/dist/components/Icons/Icons';

type Props = {
    planName: string;
};

function PlanIcon({ planName }: Props) {
    switch (true) {
        case new RegExp('Premium').test(planName):
            return <IconPlanPremium color={colors.floppyAqua} />;
        case new RegExp('Lite').test(planName):
            return <IconPlanLite color={colors.floppyAqua} />;
        case new RegExp('Pro').test(planName):
            return <IconPlanPro color={colors.floppyAqua} />;
        default:
            return <IconPlanFree color={colors.floppyAqua} />;
    }
}

export default PlanIcon;
