import { Utils, helpers } from 'autenti-components';

function RedirectToAccounts(): null {
    if (helpers.hasLoginCookie()) {
        return null;
    }
    const win: Window = window;
    // @ts-ignore
    win.location = `${Utils.getUrlTo('account')}?return=${encodeURIComponent(process.env.REACT_APP_PUBLIC_URL)}` as const;
    return null;
}

export default RedirectToAccounts;
