import styled from 'styled-components';
import {colors} from '@commoncomponents/frontend-components/dist/styled';
import {FormattedMessage} from 'react-intl';
import AlertIcon from '@commoncomponents/frontend-components/dist/components/AlertIcon';

type PropsType = {
    isSuperAdmin: boolean;
    hasLoginCookie: boolean;
};

export const Style = styled.div`
  padding: 16px 0;
  width: 100%;
  background: ${colors.humbleMilky};
  color: ${colors.realCobalt};
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 22px;
  border-radius: 8px;
  margin-bottom: 24px;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: 16px;

  iconDiv > .AlertIcon-INFO-2, .AlertIcon-INFO-8 {
    height: 44px;
    width: 20px;
    margin: 0 16px;

    svg {
      width: 23px;
      height: 20px;
    }
  }
`;

const LogoutInfoBar = ({isSuperAdmin, hasLoginCookie}: PropsType) => {
    return (
        <Style>
            <Content>
                <div className={'iconDiv'}><AlertIcon variant="INFO"/></div>
                {isSuperAdmin ?
                    <FormattedMessage
                        id="MSMarketplace.Login.MessageNonAdmin"
                        defaultMessage="Aby dokończyć aktywację zakupionych subskrypcji musisz posiadać uprawnienia Super Administratora."
                    />
                    :
                    hasLoginCookie ?
                        <FormattedMessage
                            id="MSMarketplace.Login.MessageNonSuperAdmin"
                            defaultMessage="Konto, na które jesteś zalogowany ma niewystarczające uprawnienia do ukończenia aktywacji. Potrzebujesz uprawnień Super Adminiatratora."
                        />
                        :
                        <FormattedMessage
                            id="MSMarketplace.Login.MessageNonAdmin"
                            defaultMessage="Aby dokończyć aktywację zakupionych subskrypcji musisz posiadać uprawnienia Super Administratora."
                        />
                }

            </Content>
        </Style>
    );
};

export default LogoutInfoBar;
