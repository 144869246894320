import MuiTablePagination from '@material-ui/core/TablePagination';

import { TablePaginationActions } from './TablePaginationActions';
import {
  PaginationTableFooter,
  StyledTableRow,
  TablePagination,
} from './styled';

export type PaginationProps = React.ComponentProps<
  typeof MuiTablePagination
> & {
  isMobile?: boolean;
};

export const Pagination = (props: PaginationProps) => {
  return (
    <PaginationTableFooter data-testid="table-pagination">
      <StyledTableRow>
        <TablePagination
          {...props}
          isMobile={props.isMobile}
          ActionsComponent={TablePaginationActions}
        />
      </StyledTableRow>
    </PaginationTableFooter>
  );
};
