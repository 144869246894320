import {OrganizationUsersResponse} from './types';
import api from "@autenti/utils/dist/helpers";
import {helpers} from "autenti-components";

export const getOrganizationUsers = async (options: {
    organizationId: string;
    size?: number;
    page?: number;
    searchText?: string;
    unpaged?: boolean;
    status?: 'ACTIVE' | 'INACTIVE';
}): Promise<OrganizationUsersResponse> => {
    const {organizationId, size, page, searchText, unpaged, status} = options;
    const searchParams = new URLSearchParams({
        organizationId,
    });

    if (searchText) {
        searchParams.set('searchText', searchText);
    }
    if (unpaged) {
        searchParams.set('unpaged', unpaged.toString());
    } else if (size && page) {
        searchParams.set('size', size.toString());
        searchParams.set('page', page.toString());
    }

    // if (status) {
        searchParams.set('status', 'ACTIVE');
    // }
    const url = `${process.env.REACT_APP_AUTENTI_API_URL}/accounts/organization?${searchParams.toString()}`;
    let response;
    await fetch(url, {
        method: 'GET',
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            return response.text().then(text => {
                throw new Error(text)
            })
        })
        .then(json => {
            response = json;
        }).catch(error => {
            throw new Error(error);
        });
    return response;
};
