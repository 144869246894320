import React from 'react';
import styled from 'styled-components/macro';

type PropsType = {
  text?: string | JSX.Element;
  subtitleText?: string | JSX.Element;
  id?: string;
  className?: string;
  titleClassName?: string;
};

export const TitleSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const TitleText = styled.h3`
  margin: 0;
  font-size: ${(props) => props.theme.fontSmall};
  color: ${(props) => props.theme.navyBlue};
  font-weight: ${(props) => props.theme.bold};
  line-height: 1.3125rem;
  text-transform: none;

  &.big-title {
    font-size: ${(props) => props.theme.fontMedium};
    font-weight: ${(props) => props.theme.bold};
  }

  &.v-big-title {
    font-size: ${(props) => props.theme.fontLarge};
    font-weight: ${(props) => props.theme.bold};
  }
`;

export const Subtitle = styled.p`
  margin: 0;
  position: relative;
  font-size: ${(props) => props.theme.fontSmall};
  color: ${(props) => props.theme.darkGray};
  line-height: 1.3125rem;
  margin-top: 8px;
`;

const Title = ({
  id,
  text,
  subtitleText,
  className = '',
  titleClassName = '',
}: PropsType) => (
  <TitleSection id={id} className={`title-section ${className}`}>
    <TitleText className={titleClassName}>{text}</TitleText>
    {subtitleText && <Subtitle>{subtitleText}</Subtitle>}
  </TitleSection>
);

export default Title;
