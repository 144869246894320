import {
  H3,
  Subheading2,
} from '@commoncomponents/frontend-components/dist/components/Text';

interface HeaderProps {
  Title: () => JSX.Element;
  Subtitle?: () => JSX.Element;
  testerProps?: { 'data-testid': string } | {};
}

export default function Header({
  Title,
  Subtitle,
  testerProps = {},
}: HeaderProps) {
  return (
    <>
      <H3 as="h1" testerProps={testerProps} className="color-dark">
        <Title />
      </H3>
      {Subtitle && (
        <Subheading2 as="h2" className="color-dark weight-regular">
          <Subtitle />
        </Subheading2>
      )}
    </>
  );
}
