import React from 'react';
import { FormattedMessage } from 'react-intl';
import { colors } from '@commoncomponents/frontend-components/dist/styled';
import {
    IconError,
    IconChat,
    IconEmail,
    IconPhone,
    IconInfo
} from '@commoncomponents/frontend-components/dist/components/Icons/Icons';
import {
    Wrapper,
    StyledCard,
    ContactWrapper,
    ContactItem,
    ContactIconWrapper,
    ContactDescription,
    ContactCallToAction,
} from '../styled';
import * as Text from '@commoncomponents/frontend-components/dist/components/Text';

function OfferCompletedMessage() {
    return (
        <Wrapper>
            <StyledCard>
                <IconInfo
                    color={colors.floppyAqua}
                    height="80px"
                    width="80px"
                />
                <Text.H3 color={colors.lameJet}>
                    <FormattedMessage
                        id="MSMarketplace.OfferCompleted"
                        defaultMessage="Subskrypcja już aktywowana, dalsze zmiany nie sa możliwe."
                    />
                </Text.H3>
                <Text.Body color={colors.lameJet} className="text-center">
                    <FormattedMessage
                        id="MSMarketplace.OfferCompletedDescription"
                        defaultMessage="Aktywacja planów została zakończona, nie ma już nic do zrobienia. Jeżeli masz pytania skontaktuj się z naszym Biurem Obsługi Klienta w celu wyjaśnienia problemu:"
                    />
                </Text.Body>
                <ContactWrapper>
                    <ContactItem>
                        <ContactIconWrapper>
                            <IconChat color={colors.rockyGrey} />
                        </ContactIconWrapper>
                        <ContactDescription>
                            <FormattedMessage
                                id="MSMarketplace.ContactChatDescription"
                                defaultMessage="Szybka pomoc (8:00-18:00)"
                            />
                        </ContactDescription>
                        <ContactCallToAction>
                            <FormattedMessage
                                id="MSMarketplace.ContactChatCallToAction"
                                defaultMessage="livechat"
                            />
                        </ContactCallToAction>
                    </ContactItem>
                    <ContactItem>
                        <ContactIconWrapper>
                            <IconEmail color={colors.rockyGrey} />
                        </ContactIconWrapper>
                        <ContactDescription>
                            <FormattedMessage
                                id="MSMarketplace.ContactEmailDescription"
                                defaultMessage="Szybka pomoc (8:00-18:00)"
                            />
                        </ContactDescription>
                        <ContactCallToAction>
                            <FormattedMessage
                                id="MSMarketplace.ContactEmailCallToAction"
                                defaultMessage="Wiadomość e-mail"
                            />
                        </ContactCallToAction>
                    </ContactItem>
                    <ContactItem>
                        <ContactIconWrapper>
                            <IconPhone color={colors.rockyGrey} />
                        </ContactIconWrapper>
                        <ContactDescription>
                            <FormattedMessage
                                id="MSMarketplace.ContactPhoneDescription"
                                defaultMessage="Infolinia (8:00-18:00)"
                            />
                        </ContactDescription>
                        <ContactCallToAction>
                            <FormattedMessage
                                id="MSMarketplace.ContactPhoneCallToAction"
                                defaultMessage="+48 22 29 05 112"
                            />
                        </ContactCallToAction>
                    </ContactItem>
                </ContactWrapper>
            </StyledCard>
        </Wrapper>
    );
}

export default OfferCompletedMessage;
