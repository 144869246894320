import { SVGProps } from 'react';
import * as Text from '@commoncomponents/frontend-components/dist/components/Text';
import { colors } from '@commoncomponents/frontend-components/dist/styled';
import { NoUsersDiv } from './styled';
import { FormattedMessage } from 'react-intl';

const NoUsersSvgIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="140"
        height="140"
        viewBox="0 0 140 140"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="70" cy="70" r="70" fill="#F7F9FB" />
        <circle cx="69" cy="70" r="5" fill="url(#paint0_linear_2_7)" />
        <circle cx="53" cy="70" r="5" fill="url(#paint1_linear_2_7)" />
        <circle cx="85" cy="70" r="5" fill="url(#paint2_linear_2_7)" />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M104.458 70.7078C104.458 89.8997 88.9004 105.458 69.7085 105.458C50.5166 105.458 34.9585 89.8997 34.9585 70.7078C34.9585 51.5159 50.5166 35.9578 69.7085 35.9578C88.9004 35.9578 104.458 51.5159 104.458 70.7078ZM106.306 99.1259C112.408 91.279 116.042 81.4178 116.042 70.7078C116.042 45.1186 95.2977 24.3745 69.7085 24.3745C44.1193 24.3745 23.3751 45.1186 23.3751 70.7078C23.3751 96.297 44.1193 117.041 69.7085 117.041C80.4185 117.041 90.2798 113.407 98.1267 107.305C98.1942 107.38 98.264 107.454 98.3362 107.526L123.53 132.72C125.792 134.982 129.459 134.982 131.721 132.72C133.982 130.458 133.982 126.791 131.721 124.529L106.527 99.3354C106.455 99.2632 106.381 99.1934 106.306 99.1259Z"
            fill="#B2BDC8"
        />
        <defs>
            <linearGradient
                id="paint0_linear_2_7"
                x1="66.0312"
                y1="74.4531"
                x2="72.9062"
                y2="65.3125"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#1872E7" />
                <stop offset="1" stop-color="#4D9AFF" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_2_7"
                x1="50.0312"
                y1="74.4531"
                x2="56.9062"
                y2="65.3125"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#1872E7" />
                <stop offset="1" stop-color="#4D9AFF" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_2_7"
                x1="82.0312"
                y1="74.4531"
                x2="88.9062"
                y2="65.3125"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#1872E7" />
                <stop offset="1" stop-color="#4D9AFF" />
            </linearGradient>
        </defs>
    </svg>
);

export const NoUsers = () => {
    return (
        <NoUsersDiv>
            <NoUsersSvgIcon />
            <Text.Subheading2 className="weight-bold" color={colors.lameJet}>
                <FormattedMessage
                    id="MSMarketplace.SelectUsersModal.NoUsers.Header"
                    defaultMessage="Nie znaleziono użytkownika spełniającego podane kryteria."
                />
            </Text.Subheading2>
        </NoUsersDiv>
    );
};
