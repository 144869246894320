import { FormattedMessage } from 'react-intl';

import LoginHeader from '../../../../components/LoginHeader';

export default function WelcomeHeader() {
  return (
    <LoginHeader
      Title={() => (
        <FormattedMessage
          id="MSMarketplace.welcomeMessage"
          defaultMessage="Wybierz konto"
        />
      )}
      Subtitle={() => (
        <FormattedMessage
          id="MSMarketplace.LoginMessage.Subheader"
          defaultMessage="Przypisz zakupione plany użytkownikom Twojej firmy, wybierając konto, na które jesteś zalogowany
                        lub użyj innego konta."
        />
      )}
    />
  );
}
