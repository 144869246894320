import {State} from "../ducks";


const getCurrentUserSelector = (state: State) => {
  const user = state.organizationUser.get('data');

  if (user) {
    const { id, givenName, surname, boxes, role } = user;
    return {
      id,
      givenName,
      surname,
      email: boxes && boxes[0] && boxes[0].identifier,
      role,
    };
  }
  return null;
};

export default getCurrentUserSelector;
