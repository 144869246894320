import { colors } from '@commoncomponents/frontend-components/dist/styled';
import styled from 'styled-components';
import { Card } from '../../../components/Card';

interface IFlexContainer {
    justifyContent?: string;
    alignItems?: string;
    flexDirection?: 'row' | 'column';
    gap?: string | number;
}

const FlexContainer = styled.div(
    ({
        justifyContent = 'center',
        alignItems = 'center',
        flexDirection = 'row',
        gap = 0,
    }: IFlexContainer) => ({
        display: 'flex',
        justifyContent: justifyContent,
        alignItems: alignItems,
        flexDirection: flexDirection,
        gap: gap,
    })
);

const UserAddedContainer = styled.div({
    width: '100%',
    minWidth: 'fit-content',
    borderRadius: '.5rem',
    padding: '1rem',
    margin: '.5rem 0',
    background: colors.rousingPearl,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    '@media (max-width: 768px)': {
        alignItems: 'start'
    }
});

const AddUserButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const DrawerContainer = styled.div`
    margin: 0;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    @media(max-width: 1024px) {
        padding: 1rem;
    }
`;

const OrderSummary = styled.div({
    display: 'flex',
    flexDrection: 'column',
    alignItems: 'start',
    background: `${colors.snowy}`,
    margin: 0,
    height: '100%',
    width: 480,
    '@media(max-width: 1024px)': {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
});

const DrawerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem;
`;

const Container = styled.div({
    height: '100%',
    width: '100%',
    display: 'grid',
    overflow: 'auto',
    gridTemplateColumns: 'auto 480px',
    '@media screen and (max-width: 1024px)': {
        display: 'flex',
        flexDirection: 'column',
    },
});

const MainContentContainer = styled.div({
    padding: '2rem 6.375rem 0 135px',
    '@media screen and (max-width: 1024px)': {
        padding: '1rem',
    },
});

const RemoveUserButton = styled.button({
    all: 'unset',
    cursor: 'pointer',
    color: colors.rockyGrey,
    borderRadius: '50%',
    padding: '.5rem',
    background: 'transparent',
    width: 24,
    height: 24,

    '&:active': {
        color: colors.crazyRose,
        background: colors.iceTouch
    },

    '@media (max-width: 768px)': {
        height: '100%',
        width: 14,
        padding: '0 0 .5rem .5rem'
    }
});

const PlanIconWrapper = styled.div({
    height: 32,
    width: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: colors.foggyMorning,
    borderRadius: '50%',
});

const StyledCard = styled(Card)({
    minWidth: 'fit-content'
});

export {
    FlexContainer,
    UserAddedContainer,
    AddUserButtonWrapper,
    DrawerContainer,
    OrderSummary,
    DrawerWrapper,
    Container,
    MainContentContainer,
    RemoveUserButton,
    PlanIconWrapper,
    StyledCard
};
