import { colors } from '@commoncomponents/frontend-components/dist/styled';
import { Card } from '../../../components/Card';
import PlanIcon from './PlanIcon';
import { FlexContainer, PlanIconWrapper } from './styled';
import * as Text from '@commoncomponents/frontend-components/dist/components/Text';
import { FormattedMessage } from 'react-intl';
import { CardContent } from '@mui/material';

type Props = { usersCount: number; plan: { [key: string]: any } };

function PlanCard({ usersCount = 0, plan }: Props) {
    return (
        <Card
            style={{
                background: colors.rousingPearl,
                marginBottom: '2rem',
                padding: '1.5rem',
            }}
        >
            <FlexContainer
                justifyContent="space-between"
                flexDirection="column"
                alignItems="start"
            >
                <Text.Subheading2
                    as="h3"
                    className="header"
                    color={colors.lameJet}
                >
                    <>
                        <FormattedMessage
                            id="MSMarketplace.Plans"
                            defaultMessage="Plany "
                        />
                        {/*{usersCount && (*/}
                        {/*    <span style={{ color: colors.rockyGrey }}>*/}
                        {/*        (1)*/}
                        {/*    </span>*/}
                        {/*)}*/}
                    </>
                </Text.Subheading2>
            </FlexContainer>
            <CardContent
                style={{
                    padding: '1rem 0 0',
                }}
            >
                <Card
                    style={{
                        border: `1px solid ${colors.airlessPlatinum}`,
                        background: colors.rousingPearl,
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center',
                        flexDirection: 'row',
                        height: 'fit-content',
                        gap: 10,
                        padding: '1rem',
                    }}
                >
                    <PlanIconWrapper>
                        <PlanIcon planName={plan.translatedName} />
                    </PlanIconWrapper>
                    <div>
                        <Text.Subheading2 color={colors.lameJet}>
                            <>
                                <FormattedMessage
                                    id="MSMarketplace.PlanType"
                                    defaultMessage="{planType}"
                                    values={{ planType: plan.translatedName }}
                                />
                            </>
                        </Text.Subheading2>
                        <Text.Body color={colors.realCobalt}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <FormattedMessage
                                    id="MSMarketplace.UsersCount"
                                    defaultMessage="Użytkownicy: {usersCount} "
                                    values={{ usersCount: usersCount }}
                                />
                                <FormattedMessage
                                    id="MSMarketplace.ExpirationDate"
                                    defaultMessage="Ważność: 1 rok od aktywacji"
                                />
                            </div>
                        </Text.Body>
                    </div>
                </Card>
            </CardContent>
        </Card>
    );
}

export default PlanCard;
