export async function activateSubscription (boxIds: Array<string>) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(decodeURI(queryString));
    const token: string = urlParams.get('token') || '';
    const decodedToken = decodeURIComponent(token.replace(/\+/g, '%2B'));
    return fetch(
        `${process.env.REACT_APP_SAAS_API_URL}/app/api/Subscription/ActivateSubscription?token=${localStorage.getItem('offer-token')}`,
        // `${process.env.REACT_APP_SAAS_API_URL}/api/Subscription/ActivateSubscription?token=${localStorage.getItem('offer-token')}`,
        {
            method: 'POST',
            credentials: "include",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                // Authorization: `Bearer `,
                // 'x-ms-marketplace-token': encodeURIComponent(decodedToken)
            },
            body: JSON.stringify({ boxIds: boxIds }),
        }
    )
        .then((response) => {
            if (!response.ok) {
                return response.text().then((text) => {
                    throw new Error(text);
                });
            }
            return response.json();
        })
        .then((json) => {
            return 'success';
        })
        .catch((error) => {
            // console.log(`error: ${error}`);
            return 'error';
        });
}
