import React, {useEffect, useState} from 'react';
import {helpers} from "autenti-components";
import {PlansUsersProvider} from "./PlansUsersProvider";
import PlanAssignment from './PlanAssignment';
import SceneWrapper from "../../components/SceneWrapper";
import { Loader } from '../../components/Loader';
import {useNavigate} from "react-router";
import {getResolveSubscription} from "../../api/saas/getResolveSubscription";
import {getOrganizationUsers} from "../../api/organization/getOrganizationUsers";
import {getPlans} from "../../api/organization/getPlans";


const MicrosoftSaasOffers = () => {
    const navigate = useNavigate();
    const [offerData, setOfferData] = useState<{[key: string]: any}>();
    const [organizationUsers, setorganizationUsers] = useState<Object>();
    const [plansList, setPlansList] = useState<Array<{[key: string]: any}>>();

    async function getOfferData() {
        const data = await getResolveSubscription();
        if(data){
            setOfferData(data);
        } else {
            navigate('/error');
        }
    }

    async function getUsers(){
        const organizationId : string = helpers.getCookie('organizationId');
        const usresData = await getOrganizationUsers({organizationId})
        if(usresData){
            setorganizationUsers(usresData)
        } else {
            navigate('/error');
        }
    }


    async function getPlansList() {
        const plansData = await getPlans();
        if(plansData){
            setPlansList(plansData);
        } else {
            navigate('/error')
        }
    }

    useEffect(() => {
        getOfferData();
        getUsers()
    }, []);

    useEffect(() => {
      getPlansList();
    }, [offerData]);

    const [plan, setPlan] = useState<{[key: string]: any}>();

    useEffect(() => {
        if (offerData && plansList) {
            setPlan(plansList.find(element => element.planId === offerData.planId.split('_')[0]));
        }
    }, [plansList, offerData]);
    
    return (
        <SceneWrapper>
            <PlansUsersProvider>
                {!offerData && <Loader />}
                {offerData && organizationUsers && plan && <PlanAssignment data={offerData} organizationUsers={organizationUsers} plan={plan} />}
            </PlansUsersProvider>
        </SceneWrapper>

    );
}

export default MicrosoftSaasOffers;
