import {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect, useSelector} from 'react-redux';
import {helpers, ducks, Utils} from 'autenti-components';
import getCurrentUserSelector from "../../../../selectors/getCurrentUserSelector";
import getIsSuperAdminSelector from "../../../../selectors/getIsSuperAdminSelector";
import {State} from "../../../../ducks";
import {defineMessages, FormattedMessage} from "react-intl";
import * as Button from '@commoncomponents/frontend-components/dist/components/Buttons';
import ButtonIcon from '@commoncomponents/frontend-components/dist/components/ButtonIcon';
import {getOrganizationUser} from "../../../../api/organization/getOrganizationUser";
import styled, {css} from 'styled-components/macro';
import {
    colors,
    fontSizes,
} from '@commoncomponents/frontend-components/dist/styled';
import {Divider} from "./styled";
import {Avatar} from 'autenti-components';
import {IconArrowRight} from '@commoncomponents/frontend-components/dist/components/Icons/Icons';
import {Link} from "react-router-dom";

type PropsType = {
    currentUser?: Object | null;
    language?: string;
};
export const WrapperDiv = styled.div`
  width: auto;
  //padding: 16px;
`
export const Wrapper = styled.div`
  //flex-direction: column;
  //justify-content: flex-start;
  //flex-wrap: wrap;
  .divider-right {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 3px;
  }
`
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  //flex-wrap: wrap;


`
export const DetailsListLi = styled.div<{ showAllText: boolean }>`
  padding: 12px 16px;
  background-color: ${colors.rousingPearl};
  border-radius: 8px;
  height: 68px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;

  p,
  h4 {
    margin-bottom: 0;
  }

  h4 {
    font-size: ${fontSizes.footnote};
    line-height: 1.5;
  }

  .avatar-placeholder {
    background: #b2bdc8;
    padding: 4px;
    width: 40px;
    height: 40px;
  }

  .avatar {
    float: left;
    width: auto;
  }

  .avatar-name,
  .avatar-email {
    color: ${(props) => props.theme.lameJet} !important;
  }

  ${({showAllText}) =>
          !showAllText &&
          css`
            p {
              margin-top: 4px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          `}
`;

const LoginForm = ({currentUser, language}: PropsType) => {
    const [organizationData, setorganizationData] = useState<any>(null);
    const currentUser2 = useSelector(getCurrentUserSelector);
    const isSuperAdmin = useSelector(getIsSuperAdminSelector);

    async function getUser() {
        const response = await getOrganizationUser();
        setorganizationData(response);
    }

    useEffect(() => {
        const loginCookie = helpers.hasLoginCookie();
        if (loginCookie) {
            getUser();
        }

    }, []);

    function goLogin() {
        // @ts-ignore
        window.location.replace(`${Utils.getUrlTo('account')}/login?return=${process.env.REACT_APP_PUBLIC_URL}`)
    }

    function goLoginToOtherAccount() {
        helpers.logout(`https://onboarding.accept.autenti.net`, false, true)
    }

    if (currentUser2) {
        return (
            <>
                test
            </>
        )
    } else {
        return (
            <>
                {!organizationData ? <Button.PrimaryRounded
                        label={
                            <FormattedMessage id="MSMarketplace.LogIn" defaultMessage="Przejdź do logowania"/>
                        }
                        onClick={goLogin}
                    /> :
                    isSuperAdmin || organizationData.role === 'SUPER_ADMIN' ?
                    <Wrapper>
                        <Link to={`step2`}>
                        <DetailsListLi showAllText={true}>
                            <WrapperDiv>
                                <Avatar
                                    givenName={organizationData.givenName === null ? organizationData.boxes[0].identifier : organizationData.givenName}
                                    surname={organizationData.surname}
                                    emailText={organizationData.boxes[0].identifier}
                                    showAvatarPlaceholder={true}
                                />
                            </WrapperDiv>
                            <WrapperDiv>
                                <ButtonIcon className="button-back">
                                    <IconArrowRight color={colors.rockyGrey}/>
                                </ButtonIcon>
                            </WrapperDiv>
                        </DetailsListLi>
                        </Link>
                        <Divider
                            className="divider-right"
                            label={
                                <FormattedMessage id="Login.dividerLabel" defaultMessage="lub"/>
                            }
                            thick={false}
                        />
                        <ButtonWrapper>
                            <Button.SecondaryRounded
                                label={
                                    <FormattedMessage id="MSMarketplace.LogInToOther" defaultMessage="Zaloguj się do innego konta"/>
                                }
                                onClick={goLoginToOtherAccount}
                            />
                        </ButtonWrapper>

                    </Wrapper>
                        :
                        <Wrapper>
                            <WrapperDiv>
                                <FormattedMessage id="MSMarketplace.LoggedUser" defaultMessage="Korzystasz z konta: "/> <b>{organizationData.boxes[0].identifier}</b>
                            </WrapperDiv>
                            <ButtonWrapper>
                                {/*<Link to="/my-page">*/}
                                <Button.SecondaryRounded
                                    label={
                                        <FormattedMessage id="MSMarketplace.LogInToOther" defaultMessage="Zaloguj się do innego konta"/>
                                    }
                                    onClick={goLoginToOtherAccount}
                                />
                                {/*</Link>*/}
                            </ButtonWrapper>
                        </Wrapper>
                }

            </>
        )
    }

}

export const mapStateToProps = (state: State) => {
    const currentUser = state.organizationUser;
    const language = state.lang.get('lang');
    return {
        currentUser,
        language
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    SNACKBAR_ACTIONS: bindActionCreators(ducks.SNACKBAR_ACTIONS, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
