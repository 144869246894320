import styled from 'styled-components/macro';
import MuiMenuItem from '@material-ui/core/MenuItem';
import MuiMenu from '@material-ui/core/Menu';

import { colors } from '@commoncomponents/frontend-components/dist/styled';

export const UsersDiv = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  grid-row-gap: 24px;

  @media (max-width: 1024px) {
    padding-bottom: 64px;
  }
`;

export const NoUsersDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  justify-content: center;
  align-items: center;
`;

export const Menu = styled(MuiMenu)`
  .MuiPaper-root {
    box-shadow: 0px 4px 16px rgba(105, 125, 142, 0.16);
  }
`;

export const MenuItem = styled(MuiMenuItem)`
  &&:hover {
    background-color: ${colors.rousingPearl};
  }

  &:hover:not(:last-child) .menu-item {
    color: ${colors.realCobalt};
  }
`;

export const SearchDiv = styled.div({
  height: '40px',
  width: '100%',
  margin: '20px auto',
  '.tooltip-content': {
      display: 'flex',
  },
});

export const ShowMoreLink = styled.a({
  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '22px',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  letterSpacing: '0.08px',
  color: colors.floppyAqua,
  textDecoration: 'none',
  cursor: 'pointer',
});

export const ShowMoreWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '.5rem auto 0'
});