import CommonComponentsLoader from '@commoncomponents/frontend-components/dist/components/Loader';
import styled from 'styled-components';

interface LoaderProps {
  className?: string;
  active?: boolean;
  background?: boolean;
  children?: JSX.Element | JSX.Element[];
  dataTestId?: string;
}

const Loader = ({
  className,
  active = true,
  background = false,
  children,
  dataTestId = 'loader',
}: LoaderProps) =>
  active ? (
    <div className={`${className} loader-indicator`} data-testid={dataTestId}>
      <CommonComponentsLoader
        className={className}
        active={active}
        background={background}
      />
    </div>
  ) : (
    <>{children}</>
  );

const Wrapper = styled(Loader)`
  width: 100%;
  height: 100%;

  & > div {
    width: 100%;
    height: 100%;
  }
`;

export default Wrapper;
