export const AccountSettingsBaseRouteUrl: string = '/account-settings';

// TODO: fetch those from BE instead of hardcoding
export type OrganizationUserRoleType = {
  id: string;
  level: number;
  messageId: string;
};

export const OrganizationUserRole: any = {
  user: {
    id: 'USER',
    level: 0,
    messageId: 'userRole',
  },

  admin: {
    id: 'ADMIN',
    level: 1,
    messageId: 'adminRole',
  },

  superAdmin: {
    id: 'SUPER_ADMIN',
    level: 2,
    messageId: 'superAdminRole',
  },
};

export const OrganizationUserRoleById: any = {
  USER: OrganizationUserRole.user,
  ADMIN: OrganizationUserRole.admin,
  SUPER_ADMIN: OrganizationUserRole.superAdmin,
};

export const BOTTOM_BAR_NAMES = {
  DOCUMENTS: 'DOCUMENTS',
  CLASSIC_SIGN: 'CLASSIC_SIGN',
  ACCOUNT: 'ACCOUNT',
  SHARED_DOCUMENTS: 'SHARED_DOCUMENTS',
  CONTACTS: 'CONTACTS',
  VALIDATOR: 'VALIDATOR',
  ESIGNATURE_VALIDATOR: 'ESIGNATURE_VALIDATOR',
  PROCESSES: 'PROCESSES',
  MORE: 'MORE',
};

export const FORM_FIELDS_ERRORS_STATUSES = {
  FIELD_REQUIRED: 'fieldRequired',
  PASSWORD_NOT_EQUAL: 'passwordNotEqual',
  FIELD_NOT_RESPECT_RULES: 'fieldNotRespectRules',
};

export const TAG_ERROR_STATUSES = {
  TAG_ALREADY_EXISTS: 'tag.already.exists',
  TAG_LENGTH_INVALID: 'tag.name.length.invalid',
};

export const SNACKBAR_BODY = {
  ADD_MESSAGE_TEMPLATE: 'addMessageTemplate',
  UPDATE_MESSAGE_TEMPLATE: 'updateMessageTemplate',
  DELETE_MESSAGE_TEMPLATE: 'removeMessageTemplate',
  DELETE_TAG: 'deleteTag',
  REMOVE_USER: 'removeUser',
  ACCOUNT_ACTIVATED: 'accountActivated',
  ACCOUNT_NOT_ACTIVATED: 'accountNotActivated',
  ACCOUNT_ACTIVATION_ALREADY_USE: 'accountActivationAlreadyUse',
  ACCOUNT_ACTIVATION_NOT_FOUND: 'accountActivationNotFound',
  REMIND_PASSWORD_SUCCESS: 'remindPasswordSuccess',
  RESET_PASSWORD_SUCCESS: 'resetPasswordSuccess',
  ACCOUNT_ALREADY_EXISTS: 'accountAlreadyExists',
  ACTIVATE_FROM_INVITATION_SUCCESS: 'activateFromInvitationSuccess',
  REMOVE_API_CLIENT: 'removeApiClient',
  UPDATE_API_CLIENT: 'updateApiClient',
  SSO_REGISTER_NEEDED: 'ssoRegisterNeeded',
  ORG_USERS_ADD_USER: 'organizationUserAddNotification',
  ORG_USERS_ADD_USER_EXISTS_ERROR: 'organizationUserAddAlreadyExistsError',
  ORG_USERS_ADD_USER_GENERIC_ERROR: 'organizationUserAddGenericError',
  ORG_USERS_UPDATE: 'organizationUserUpdateNotification',
  ORG_USERS_DELETE_USER: 'organizationUserDeleteNotification',
  ORG_USERS_DELETE_USER_ERROR: 'organizationUserDeleteError',
  ORG_USERS_RESET_PASSWORD: 'organizationUserResetPasswordNotification',
  ORG_LOGO_DELETE: 'organizationLogoRemove',
  ORG_LOGO_DELETE_ERROR: 'organizationLogoRemoveError',
  ORG_LOGO_ADD: 'organizationLogoUpload',
  ORG_LOGO_ADD_UNSUPPORTED_ERROR: 'unsupportedfileformat',
  ORG_LOGO_ADD_TOO_LARGE_ERROR: 'imagetoolarge',
  ORG_LOGO_ADD_RATIO_ERROR: 'imagerationotallowed',
  ORG_LOGO_ADD_ERROR: 'organizationLogoUploadError',
  ORG_PAYMENT_SUCCESS: 'organizationPaymentSuccess',
  ORG_PAYMENT_REMOVE_USER: 'organizationPaymentRemoveUser',
  INVOICE_FORM_VALIDATION_TAX_NUMBER_ERROR: 'taxNumberError',
  INVOICE_FORM_VALIDATION_TAX_NUMBER_VALID: 'taxNumberValid',
  SSO_ACTIVATED: 'ssoActivated',
  SSO_DELETED: 'ssoDeleted',
  CHANGE_PASSWORD_SUCCESS: 'changePasswordSuccess',
  CONFIGURE_AUTHENTICATOR_SUCCESS: 'configureAuthenticatorSuccess',
  MOBILE_AUTH_APPROVED: 'mobileAuthApproved',
  MOBILE_AUTH_REJECTED: 'mobileAuthRejected',
  MOBILE_AUTH_REMOVED: 'mobileAuthRemoved',
  AUTHENTICATOR_REMOVED_INFO: 'authenticatorRemovedInfo',
  PASSWORD_TOO_MANY_ATTEMPTS: 'passwordTooManyAttempts',
  EMAIL_ADDRESS_NOT_ALLOWED: 'emailAddressNotAllowed',
  REGON_DATA_ERROR: 'regonDataError',
};

export const RESTRICTIONS = {
  DOCUMENT_WITH_SMS_AUTHORIZATION: 'DOCUMENT_WITH_SMS_AUTHORIZATION',
  DOCUMENT_WITH_SIGNATURE_ORDER: 'DOCUMENT_WITH_SIGNATURE_ORDER',
  API_CLIENT_MANAGEMENT: 'API_CLIENT_MANAGEMENT',
  DOCUMENT_WITH_APPROVER: 'DOCUMENT_WITH_APPROVER',
  ORGANIZATION_DEFAULT_DOCUMENT_TAGS: 'ORGANIZATION_DEFAULT_DOCUMENT_TAGS',
  DOCUMENT_MESSAGE_TEMPLATES_CREATION: 'DOCUMENT_MESSAGE_TEMPLATES_CREATION',
  DOCUMENT_TAGS: 'DOCUMENT_TAGS',
  DOCUMENT_WITH_ORGANIZATION_LOGO: 'DOCUMENT_WITH_ORGANIZATION_LOGO',
  DOCUMENT_WITH_REVIEWER: 'DOCUMENT_WITH_REVIEWER',
  DOCUMENT_HANDOVER: 'DOCUMENT_HANDOVER',
};
