import styled from 'styled-components';
import CommonDivider from "@commoncomponents/frontend-components/dist/components/Divider";

export const InputLabelWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`;
export const Divider = styled(CommonDivider)`
  margin-top: 8px;
  grid-area: sso-divider;

  @media (max-width: 1024px) {
    display: none;
  }
`;
