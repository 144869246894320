import React, {useEffect, useState} from 'react';
import * as Text from '@commoncomponents/frontend-components/dist/components/Text';
import {FormattedMessage} from 'react-intl';
import {colors} from '@commoncomponents/frontend-components/dist/styled';
import {CardContent} from '@material-ui/core';
import {AddUserButton} from './Users/AddUserButton';
import {IconInfo} from '@commoncomponents/frontend-components/dist/components/Icons/Icons';
import InfoBox from '@commoncomponents/frontend-components/dist/components/InfoBox';
import SelectUsersModal from './Users/SelectUsersModal';
import {useUsersContext} from './contexts/UsersContext';
import {User} from './Users/SelectUsersTable';
import {Avatar} from '../../components/Avatar';
import * as Button from '@commoncomponents/frontend-components/dist/components/Buttons';
import {
    IconDelete,
    IconError,
} from '@commoncomponents/frontend-components/dist/components/Icons/Icons';
import PlanCard from './PlanAssignment/PlanCard';
import Title from '../../components/Title';
import {
    UserAddedContainer,
    AddUserButtonWrapper,
    DrawerContainer,
    OrderSummary,
    DrawerWrapper,
    Container,
    MainContentContainer,
    RemoveUserButton,
    StyledCard
} from './PlanAssignment/styled';
import Snackbar from '@commoncomponents/frontend-components/dist/components/Snackbar';
import {useIsMobile} from './utils/useIsMobile';
import {useNavigate} from 'react-router';
import {activateSubscription} from 'api/saas/activateSubscription';

function PlanAssignment({
                            data,
                            plan,
                            organizationUsers
                        }: {
    data: Object | any;
    plan: { [key: string]: any };
    organizationUsers: Object;
}) {
    const {state, fetchUsers} = useUsersContext();
    const initialState = {};
    useEffect(() => {
        fetchUsers({count: 0, search: '', size: 10, page: 0});
        state?.users.map((element) =>
            Object.assign(initialState, {[element.id]: false})
        );
    }, [fetchUsers]);

    const [isSelectModalOpen, setIsSelectModalOpen] = useState<boolean>(false);

    function handleOpen() {
        !isSelectModalOpen && setIsSelectModalOpen(true);
    }

    function handleClose() {
        isSelectModalOpen && setIsSelectModalOpen(false);
    }

    const [assignedUsers, setAssignedUsers] = useState<Array<string | number>>(
        []
    );

    function handleModalSubmit() {
        setAssignedUsers(
            Object.keys(selectedUsers).filter((u) => selectedUsers[u])
        );
    }

    const [selectedUsers, setSelectedUsers] = useState<{
        [key: string | number]: boolean;
    }>(initialState);

    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const isMobile = useIsMobile();

    function handleChange(
        event: React.ChangeEvent<HTMLInputElement>,
        user: User
    ) {
        setSelectedUsers({
            ...selectedUsers,
            [user.id]: event.target.checked,
        });
    }

    function handleRowTap(
        event: React.MouseEvent<HTMLTableRowElement>,
        user: User
    ) {
        if (data.quantity > Object.keys(selectedUsers).filter(e => selectedUsers[e]).length) {
            setSelectedUsers({
                ...selectedUsers,
                [user.id]: !selectedUsers[user.id],
            });
        }
    }

    function handleRemoveAssignedUser(userId: string | number) {
        setAssignedUsers([
            ...assignedUsers.filter((u) => Number(u) !== userId),
        ]);
        setShowSnackbar(true);
    }

    const [boxIds, setBoxIds] = useState<Array<string>>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const obj = {};
        assignedUsers.map((u) => (obj[u] = true));
        setSelectedUsers(obj);
        let tempboxids: any = []
        for (let entry of assignedUsers) {
            const id = state?.users.find((user) => user.id === Number(entry)).boxId;
            tempboxids.push(id)
        }
        setBoxIds(tempboxids)
    }, [assignedUsers]);



    const navigate = useNavigate();
    async function handleActivateSubscriptionClick() {
        if (boxIds.length == data?.quantity) {
            setLoading(true);
            const response = await activateSubscription(boxIds);
            if (response === 'error') {
                navigate('/error');
            } else if (response === 'success') {
                navigate('/step3');
            }
        }
    }

    return (
        <Container>
            <MainContentContainer>
                <Text.H3 as="h2" color={colors.lameJet}>
                    <FormattedMessage
                        id="MSMarketplace.PlanAssignment"
                        defaultMessage="Przypisanie planów"
                    />
                </Text.H3>
                <StyledCard>
                    <Title
                        id="plan-assignment"
                        text={
                            <FormattedMessage
                                id="MSMarketplace.ChooseUsersToAsignPlans"
                                defaultMessage="Wybierz użytkowników, którym chcesz przypisać zakupione plany"
                            />
                        }
                        subtitleText={
                            <FormattedMessage
                                id="MSMarketplace.NextAcitvatePlanOfChosenUsers"
                                defaultMessage="Następnie aktywuj plany wybranym osobom."
                            />
                        }
                    />
                    <CardContent style={{padding: '1rem 0 0'}}>
                        {assignedUsers.length >= 1 &&
                            assignedUsers.length < data?.quantity && (
                                <InfoBox
                                    variant="ERROR"
                                    borderless
                                    startIcon={
                                        <IconError color={colors.crazyRose}/>
                                    }
                                    message={
                                        <Text.Body
                                            as="span"
                                            color={colors.orangeSorrel}
                                        >
                                            <FormattedMessage
                                                id="MSMarketplace.ErrorInsuffictientUsersNumber"
                                                defaultMessage="Przypisz wszystkie zakupione plany użytkownikom Twojej firmy."
                                            />
                                        </Text.Body>
                                    }
                                />
                            )}
                        <Text.Body color={colors.lameJet}>
                            <>
                                <FormattedMessage
                                    id="MSMarketplace.chosenUsers"
                                    defaultMessage="Wybranych użytkowników: "
                                />
                                <span style={{fontWeight: 'bold'}}>
                                    {assignedUsers.length}/{data?.quantity}
                                </span>
                            </>
                        </Text.Body>
                        {assignedUsers.length ? (
                            <div style={{marginTop: '1rem'}}>
                                {assignedUsers.map((user) => {
                                    const userItem = state.users.find(
                                        (u) => u.id === Number(user)
                                    );
                                    return (
                                        <UserAddedContainer>
                                            <Avatar
                                                name={userItem.name}
                                                surname={userItem.surname}
                                                email={userItem.email}
                                            />
                                            <RemoveUserButton
                                                onClick={() => {
                                                    handleRemoveAssignedUser(
                                                        userItem.id
                                                    );
                                                }}
                                            >
                                                <IconDelete
                                                    width={
                                                        isMobile ? '14' : '24'
                                                    }
                                                    height={
                                                        isMobile ? '14' : '24'
                                                    }
                                                    viewBox={
                                                        isMobile
                                                            ? '0 0 14 14'
                                                            : '0 0 24 24'
                                                    }
                                                />
                                            </RemoveUserButton>
                                        </UserAddedContainer>
                                    );
                                })}
                            </div>
                        ) : (
                            <InfoBox
                                variant="PRIMARY"
                                borderless
                                startIcon={<IconInfo/>}
                                message={
                                    <Text.Body
                                        as="span"
                                        color={colors.realCobalt}
                                    >
                                        <FormattedMessage
                                            id="MSMarketplace.ChooseFirstUser"
                                            defaultMessage="Wybierz pierwszego użytkownika."
                                        />
                                    </Text.Body>
                                }
                            />
                        )}
                        <AddUserButtonWrapper>
                            <AddUserButton onClick={handleOpen}/>
                        </AddUserButtonWrapper>
                    </CardContent>
                </StyledCard>
            </MainContentContainer>
            <OrderSummary>
                <DrawerContainer>
                    <Text.H4 as="h3" color={colors.lameJet}>
                        <FormattedMessage
                            id="MSMarketplace.OrderSummary"
                            defaultMessage="Podsumowanie zamówienia"
                        />
                    </Text.H4>
                    <PlanCard
                        usersCount={data?.quantity ? data.quantity : 0}
                        plan={plan}
                    />
                    <DrawerWrapper>
                        <Button.PrimaryRounded
                            label={
                                <FormattedMessage
                                    id="MSMarketplace.ActivatePlans"
                                    defaultMessage="Aktywuj plany"
                                />
                            }
                            onClick={handleActivateSubscriptionClick}
                            disabled={loading}
                        />
                        <Text.Footnote color={colors.rockyGrey}>
                            <FormattedMessage
                                id="MSMarketplace.OrderSummaryFootnote"
                                defaultMessage="Jeśli przysługują Ci prawa konsumenta: Aktywacja Planu spowoduje, że prawo do odstąpienia od zamówienia w terminie 14 dni od jego złożenia nie będzie Ci przysługiwało."
                            />
                        </Text.Footnote>
                    </DrawerWrapper>
                </DrawerContainer>
            </OrderSummary>
            <SelectUsersModal
                isOpen={isSelectModalOpen}
                onClose={handleClose}
                selectedUsers={selectedUsers}
                usersQuantity={data.quantity}
                onChange={handleChange}
                onRowTap={handleRowTap}
                onSubmit={handleModalSubmit}
                organizationUsers={organizationUsers}
            />
            {showSnackbar && (
                <Snackbar
                    open={showSnackbar}
                    handleClose={() => setShowSnackbar(false)}
                    message={
                        <Text.Body as="span" color={colors.cannyBlue}>
                            <FormattedMessage
                                id="MSMarketplace.UserRemovedInfoBox"
                                defaultMessage="Użytkownik usunięty z listy."
                            />
                        </Text.Body>
                    }
                    direction="down"
                    horizontal="center"
                    vertical={isMobile ? 'bottom' : 'top'}
                    variant={'INFO'}
                    autoHideDuration={4000}
                    variantIconVisible
                    closeIconVisible
                />
            )}
        </Container>
    );
}

export default PlanAssignment;
