import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {helpers, ducks} from 'autenti-components';
import {connect} from 'react-redux';
import {State} from '../../ducks';
import {bindActionCreators} from 'redux';
import {FormattedMessage} from 'react-intl';
import {DEFAULT_LANGUAGES} from '@commoncomponents/frontend-components/dist/components/LanguagePicker/LanguagesData';
import TopNavigation from '@commoncomponents/frontend-components/dist/components/TopNavigation';
import useNavigationMenuList from '../../hooks/useNavigationMenuList';

type PropType = {
    lang: string;
    isOrganization: boolean;
    navigationVariant: any;
    currentUser: any;
    organizationLogo: string;
    planShortName: string;
    location: any;
    isMobileView: boolean | null;
    languages?: { code: string }[];
    PLAN_SUBSCRIPTIONS_ACTIONS: { GET_BILLINGS: () => void };
    ORGANIZATION_PAYMENT_ACTIONS: {
        CHANGE_UPGRADE_MODAL_VISIBILITY: () => void;
    };
    LANG_ACTIONS: {
        FETCH_REQUEST: () => void;
        CHANGE_LANGUAGE: (a: string) => void;
        UPDATE_REQUEST: (a: any) => void;
    };
    NAVIGATION_ACTIONS: {
        SET_NAVIGATION_VARIANT: (a: string) => void;
    };
    // upgradeAccountButtonProps: {
    //     label: string | JSX.Element;
    //     longLabel: string | JSX.Element;
    //     onClick: () => void;
    //     visible: boolean;
    // };
};

const TopMenuStyles = styled.div`
  min-height: 64px;

  .title-text {
    margin-right: 15px;
  }

  @media (min-width: 768px) {
    min-height: 72px;
  }
`;

export const TopNavigationComponent = (props: PropType) => {
    const {
        lang,
        isOrganization,
        currentUser,
        organizationLogo,
        planShortName,
        languages,
        location,
        isMobileView,
        ORGANIZATION_PAYMENT_ACTIONS: {CHANGE_UPGRADE_MODAL_VISIBILITY},
        PLAN_SUBSCRIPTIONS_ACTIONS: {GET_BILLINGS},
        LANG_ACTIONS: {FETCH_REQUEST, CHANGE_LANGUAGE, UPDATE_REQUEST},
        NAVIGATION_ACTIONS: {SET_NAVIGATION_VARIANT},
    } = props;
    const defaultLanguageCode =
        (lang && lang.toUpperCase()) ||
        (helpers.getNotLoggedLanguageCookie() &&
            helpers.getNotLoggedLanguageCookie().toUpperCase()) ||
        helpers.getLocaleLanguageOrDefault().toUpperCase();

    const [defaultLanguage, setDefaultLanguage] = useState(defaultLanguageCode);

    const menuList = useNavigationMenuList();

    const setLanguage = (lang: string) => {
        // @todo check if the whole _case stuff is needed
        UPDATE_REQUEST({languageCode: lang});
        CHANGE_LANGUAGE(lang.toLowerCase());
        helpers.setNotLoggedLanguageCookie(lang.toLowerCase());
        helpers.setLanguageCookie(lang.toLowerCase());
        setDefaultLanguage(lang.toUpperCase());
    };

    const handleLanguagePickerClick = () => {
        SET_NAVIGATION_VARIANT('LANGUAGE_PICKER');
    };

    useEffect(() => {
        const url = new URLSearchParams(window.location.search);
        const urlParamLang = url.get('lang')?.toLowerCase();
        if (urlParamLang && helpers.isAllowedLanguage(urlParamLang)) {
            setLanguage(urlParamLang.toUpperCase());
        }
    }, []);

    useEffect(() => {
        setDefaultLanguage(lang.toUpperCase());
    }, [lang]);

    useEffect(() => {
        FETCH_REQUEST();
    }, [defaultLanguage]);
    const topNavigationProps = {
        currentUser: currentUser ? {
                givenName: currentUser.givenName,
                surname: currentUser.surname,
                email:
                    currentUser.boxes &&
                    currentUser.boxes[0] &&
                    currentUser.boxes[0].identifier,
                role: currentUser.role,
            }
            : null,
        isOrganization,
        menuList,
        planShortName,
        languagePickerProps: {
            onlyFlagIcon: true,
            languageCodes: [
                {
                    "code": "PL"
                },
                {
                    "code": "EN"
                }
            ],
            defaultLanguageCode: defaultLanguage,
            handleChange: setLanguage,
            handleLanguagePickerClick: handleLanguagePickerClick,
        },
        appName: 'ACCOUNTS',
        logoutText: (
            <FormattedMessage
                id="TopNavigationComponent.logoutText"
                defaultMessage="Wyloguj"
            />
        ),
        organizationLogo,
        showLogo: true,
        uploadLogoLabel: (
            <FormattedMessage
                id="TopNavigationComponent.uploadLogoLabel"
                defaultMessage="Dodaj logo"
            />
        ),
        supportTooltipText: (
            <FormattedMessage
                id="TopNavigationComponent.HelpCenterLabel"
                defaultMessage="Centrum Pomocy"
            />
        ),
        handleUploadLogo: () => {
        },
        upgradeAccountButtonProps: {
            label: '',
            longLabel: '',
            onClick: () => {
            },
            visible: false
        }
    };

    return (
        <TopMenuStyles className="TopNavigationComponent">
            <TopNavigation {...topNavigationProps} />
        </TopMenuStyles>
    );
};

export const mapStateToProps = (state: State) => {
    const lang = state.lang.get('lang');
    const languages = state.lang.get('languages');
    const isOrganization = state.account.get('isOrganization');
    const organizationLogo = state.organizationLogo.get('logo');
    const planShortName: any = state.planSubscriptions.getIn([
        'billing',
        'subscription',
        'planShortName',
    ]);
    const organizationUserData = state.organizationUser.get('data');
    const currentUser = organizationUserData;
    const isMobileView = state.responsive.get('isMobileView');

    return {
        lang,
        currentUser,
        organizationLogo,
        isOrganization,
        planShortName,
        languages,
        isMobileView,
    };
};

export const mapDispatchToProps = (dispatch: any) => ({
    LANG_ACTIONS: bindActionCreators(ducks.LANG_ACTIONS, dispatch),
    ORGANIZATION_PAYMENT_ACTIONS: bindActionCreators(
        ducks.ORGANIZATION_PAYMENT_ACTIONS,
        dispatch,
    ),
    PLAN_SUBSCRIPTIONS_ACTIONS: bindActionCreators(
        ducks.PLAN_SUBSCRIPTIONS_ACTIONS,
        dispatch,
    ),
    NAVIGATION_ACTIONS: bindActionCreators(ducks.NAVIGATION_ACTIONS, dispatch),
});


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TopNavigationComponent);
