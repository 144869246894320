import { createAction, handleActions } from 'redux-actions';
import { Map } from 'immutable';
import { createSelector } from 'reselect';

export const DEFAULT_PAGE_SIZE = 10;

export const actions: any = {
  REQUEST: createAction('ACCOUNTS/ORGANIZATION_USERS/REQUEST'),
  SUCCESS: createAction('ACCOUNTS/ORGANIZATION_USERS/SUCCESS'),
  ERROR: createAction('ACCOUNTS/ORGANIZATION_USERS/ERROR'),
  SET_PAGE: createAction('ACCOUNTS/ORGANIZATION_USERS/SET_PAGE'),
  SET_PAGE_SIZE: createAction('ACCOUNTS/ORGANIZATION_USERS/SET_PAGE_SIZE'),
  SELECT_USER: createAction('ACCOUNTS/ORGANIZATION_USERS/SELECT_USER'),
  SELECT_ALL_USERS: createAction(
    'ACCOUNTS/ORGANIZATION_USERS/SELECT_ALL_USERS',
  ),
};

export type ActionType = {
  payload: Object;
  type: string;
};

type State = Map<string, any>;

export const initialState: State = Map({
  data: null,
  isProcessing: null,
  selectAll: false,
  error: null,
  loaded: false,
  page: 0,
  pageSize: DEFAULT_PAGE_SIZE,
  totalElements: null,
});

const reducer = handleActions(
  {
    [actions.REQUEST]: (state) =>
      state.merge({
        isProcessing: true,
        error: false,
      }),
    [actions.SUCCESS]: (state, action: any) => {
      return state.merge({
        data: action.payload.content.map((obj: any) => ({
          ...obj,
          selected: false,
        })),
        isProcessing: false,
        error: false,
        loaded: true,
        page: action.payload.number,
        pageSize: action.payload.size,
        totalElements: action.payload.totalElements,
      });
    },
    [actions.ERROR]: (state) =>
      state.merge({
        isProcessing: false,
        error: true,
        loaded: true,
      }),
    [actions.SET_PAGE]: (state, action: any) => {
      const maxPageNum = Math.floor(
        (state.get('totalElements') || 0) / state.get('pageSize'),
      );
      const page = Math.max(0, Math.min(action.payload, maxPageNum));
      return state.set('page', page).set('selectAll', false);
    },
    [actions.SET_PAGE_SIZE]: (state, action) =>
      state.merge({
        page: 0,
        pageSize: action.payload,
        selectAll: false,
      }),
    [actions.SELECT_USER]: (state, action) => {
      const users = state.get('data');
      const idx = state
        .get('data')
        .findIndex((item: any) => item.id === action.payload);

      const isAllchecked = users.every((item: any) => item.selected === false);
      if (isAllchecked) {
        return state.updateIn(['data', idx, 'selected'], (val: any) => !val);
      } else {
        return state
          .updateIn(['data', idx, 'selected'], (val: any) => !val)
          .set('selectAll', false);
      }
    },
    [actions.SELECT_ALL_USERS]: (state) => {
      const users = state.get('data');
      users.forEach((element: any) => {
        if (state.get('selectAll') === false) {
          element.selected = true;
        }
        if (state.get('selectAll') === true) {
          element.selected = false;
        }
      });

      return state.update('selectAll', (val: any) => !val);
    },
  },
  initialState,
);

export const selectors = {
  totalPages: createSelector(
    (state: any) => state.organizationUsers.get('totalElements'),
    (state: any) => state.organizationUsers.get('pageSize'),
    (totalElements, pageSize) =>
      Math.max(Math.ceil((totalElements || 0) / pageSize), 1),
  ),
};

export default reducer;
