import { getPlan } from './getPlan';
import { OrganizationUsersResponse } from '../../../api/organization/types';
import { UserDetails } from '../types';

export const convertOrganizationUserResponse = (
    content: OrganizationUsersResponse['content']
): UserDetails[] => {

    return content.map((r) => ({
        id: r.id,
        email: r.boxIdentifier,
        name: r.givenName,
        plan: r.planSubscription ? getPlan(r.planSubscription?.name) : 'Free',
        role: r.role,
        status: r.status,
        surname: r.surname,
        boxId: r.boxExternalId,
    }));
};
