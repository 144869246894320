import { UsersContextProvider } from './contexts/UsersContext';

interface Props {
    children: React.ReactNode;
}

export const PlansUsersProvider: React.FC<Props> = (props) => {
    return (
        <UsersContextProvider>
            <>{props.children}</>
        </UsersContextProvider>
    );
};
