import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Carousel from '@commoncomponents/frontend-components/dist/components/Carousel';
import carousel1 from './media/login_illustration.png';

const SplashScreenDesktop = styled.div`
  height: 100%;
  display: none;

  @media (min-width: 1024px) {
    display: block;
  }
`;
const CarouselComponent = () => (
  <SplashScreenDesktop>
    <Carousel
      mode="redesign"
      items={[
        {
          background: carousel1,
          description: (
            <FormattedMessage
              id="Accounts.CarouselDescription"
              defaultMessage="Podpisuj dokumenty jednym kliknięciem w dowolnym miejscu i czasie"
            />
          ),
          title: (
            <FormattedMessage
              id="Accounts.CarouselOneTitle"
              defaultMessage="Wygodny e-podpis"
            />
          ),
        },
      ]}
      slideDirection="right"
    />
  </SplashScreenDesktop>
);

export default CarouselComponent;
