import { FormattedMessage } from 'react-intl';
import { Body } from '@commoncomponents/frontend-components/dist/components/Text';
import * as Link from '@commoncomponents/frontend-components/dist/components/Link';

import {
  Footer as StyledFooter,
  CreateLinkDiv,
} from './styled';
import {Utils} from "autenti-components";

const Footer = () => {
  return (
    <StyledFooter>
      <CreateLinkDiv>
        <Body className="color-secondary question" as="span">
          <FormattedMessage
            defaultMessage="Nie masz konta?"
            id="Login.AccountExistQuestion"
          />
        </Body>
        <Link.Standard id="accounts-register_account-link" href={`${Utils.getUrlTo('accounts')}/register?account-type=organization&start-step=1&return-url=${process.env.REACT_APP_PUBLIC_URL}`}>
          <Body as="span">
            <FormattedMessage
              id="Login.CreateFreeAccount"
              defaultMessage="Załóż darmowe konto firmowe"
            />
          </Body>
        </Link.Standard>
      </CreateLinkDiv>
    </StyledFooter>
  );
};

export default Footer;
