import React from 'react';
import { Avatar } from '../../../components/Avatar';
import { Checkbox } from '../../../components/Checkbox';
import { Table, TableCell } from '../../../components/Table';
import { NoUsers } from './NoUsers';
import PlanChip from '@commoncomponents/frontend-components/dist/components/PlanChip';
import { colors } from '@commoncomponents/frontend-components/dist/styled';
import { useIsMobile } from '../utils/useIsMobile';
import Tooltip from '@commoncomponents/frontend-components/dist/components/Tooltip';
import { FormattedMessage } from 'react-intl';
import Loader from '../../../components/Loader/LoaderWrapped';

export type User = {
    id: number;
    name: string;
    surname: string;
    email: string;
    [key: string]: string | number;
};

type Props = {
    users: Array<User>;
    usersQuantity: number;
    onChange: (event: React.ChangeEvent<HTMLInputElement>, user: User) => void;
    onRowTap: (
        event: React.MouseEvent<HTMLTableRowElement>,
        user: User
    ) => void;
    isChecked: { [key: string]: boolean };
    isLoading: boolean;
};

const RenderRow = (
    user: User,
    isUsersLimitReached: boolean,
    handleChange,
    isChecked
) => {
    return (
        <>
            <TableCell
                width={20}
                height={20}
                align="center"
                style={{ background: `${colors.rousingPearl}` }}
            >
                <Tooltip
                    message={
                        <FormattedMessage
                            id="MSMarketplace.UserQuantityLimitReachedTooltip"
                            defaultMessage="Przypisałeś już wszystkie zakupione plany. Aby przypisać plan temu użytkownikowi, odznacz innego."
                        />
                    }
                    autoHideAfter={3000}
                    preferredPlacement="top"
                    theme="dark"
                    disabled={!isUsersLimitReached || isChecked}
                >
                    <Checkbox
                        onChange={(event) => {
                            handleChange(event, user);
                        }}
                        checked={isChecked}
                        disabled={isUsersLimitReached && !isChecked}
                    />
                </Tooltip>
            </TableCell>
            <TableCell
                align="left"
                color={colors.rousingPearl}
                style={{
                    background: `${colors.rousingPearl}`,
                }}
            >
                <Avatar
                    name={user.name}
                    surname={user.surname}
                    email={user.email}
                />
            </TableCell>
            <TableCell
                width={30}
                style={{ background: `${colors.rousingPearl}` }}
            >
                <PlanChip text={user.plan as string} />
            </TableCell>
        </>
    );
};

function SelectUsersTable({
    users,
    usersQuantity,
    onChange,
    onRowTap,
    isChecked,
    isLoading,
}: Props) {
    const isUsersLimitReached =
        Object.keys(isChecked).filter((element) => isChecked[element]).length >=
        usersQuantity;
    const isMobile = useIsMobile();

    return (
        <>
            <Table<User>
                items={users}
                HeaderCells={() => <></>}
                renderRow={(user) =>
                    RenderRow(
                        user,
                        isUsersLimitReached,
                        onChange,
                        isChecked[user.id]
                    )
                }
                isMobile={isMobile}
                onRowClick={onRowTap}
                NoResultComponent={isLoading ? Loader : NoUsers}
            />
        </>
    );
}

export default SelectUsersTable;
