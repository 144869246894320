import { useState } from 'react';
import MuiTableCell from '@material-ui/core/TableCell';
import {
  TableRowSpacer,
  RecordTableRow,
  ExpandedRecordTableRow,
} from './styled';
import { ExpandButton } from './ExpandButton';

type TableRowProps<T> = {
  isMobile?: boolean;
  item: T;
  onClick?: (event: React.MouseEvent<HTMLTableRowElement>, item: T) => void;
  renderRow: (item: T) => JSX.Element;
  renderMobileRow?: (item: T) => JSX.Element;
  renderExpandableRow?: (item: T) => JSX.Element;
};

export const TableRow = <T,>(props: TableRowProps<T>) => {
  const {
    isMobile,
    item,
    renderRow,
    renderMobileRow,
    renderExpandableRow,
    onClick,
  } = props;
  const [expanded, setExpanded] = useState<boolean>(false);

  const onExpandButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setExpanded(!expanded);
  };

  const handleOnClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
    if (onClick) onClick(event, item);
  };

  return (
    <>
      <RecordTableRow
        data-testid={
          isMobile && renderMobileRow
            ? 'table-mobile-record-row'
            : 'table-record-row'
        }
        onClick={handleOnClick}
      >
        {renderExpandableRow && (
          <MuiTableCell align="left" width={30}>
            <ExpandButton onClick={onExpandButtonClick} expanded={expanded} />
          </MuiTableCell>
        )}
        {isMobile && renderMobileRow ? renderMobileRow(item) : renderRow(item)}
      </RecordTableRow>
      <TableRowSpacer size={expanded ? 'medium' : 'small'} />
      {expanded && renderExpandableRow && (
        <>
          <ExpandedRecordTableRow>
            {renderExpandableRow(item)}
          </ExpandedRecordTableRow>
          <TableRowSpacer size={expanded ? 'large' : 'small'} />
        </>
      )}
    </>
  );
};
