import React from 'react';
import { FormattedMessage } from 'react-intl';
import { colors } from '@commoncomponents/frontend-components/dist/styled';
import {
    IconError,
    IconChat,
    IconEmail,
    IconPhone,
} from '@commoncomponents/frontend-components/dist/components/Icons/Icons';
import {
    Wrapper,
    StyledCard,
    ContactWrapper,
    ContactItem,
    ContactIconWrapper,
    ContactDescription,
    ContactCallToAction,
    ButtonsWrapper,
} from '../styled';
import * as Text from '@commoncomponents/frontend-components/dist/components/Text';
import * as Button from '@commoncomponents/frontend-components/dist/components/Buttons';
import { useIsMobile } from '../utils/useIsMobile';
import { Utils } from 'autenti-components';

function Error() {
    const isMobile = useIsMobile();
    function Cancel(){
       // @ts-ignore
        window.location.replace(Utils.getUrlTo('account'));
        return null;
    }
    function Rety() {
        window.history.back();
        return null;
    }
    return (
        <Wrapper>
            <StyledCard>
                <IconError
                    color={colors.crazyRose}
                    height="80px"
                    width="80px"
                />
                <Text.H3 color={colors.lameJet}>
                    <FormattedMessage
                        id="MSMarketplace.ErrorUnexpected"
                        defaultMessage="Wystąpił błąd"
                    />
                </Text.H3>
                <Text.Body color={colors.lameJet} className="text-center">
                    <FormattedMessage
                        id="MSMarketplace.ErrorUnexpectedDescription"
                        defaultMessage="Podczas aktywacji planów wystąpił  nieoczekiwany błąd. Spróbuj dokończyć proces aktywacji subskrypcji jeszcze raz lub skontaktuj się z naszym Biurem Obsługi Klienta w celu wyjaśnienia problemu:"
                    />
                </Text.Body>
                <ContactWrapper>
                    <ContactItem>
                        <ContactIconWrapper>
                            <IconChat color={colors.rockyGrey} />
                        </ContactIconWrapper>
                        <ContactDescription>
                            <FormattedMessage
                                id="MSMarketplace.ContactChatDescription"
                                defaultMessage="Szybka pomoc (8:00-18:00)"
                            />
                        </ContactDescription>
                        <ContactCallToAction>
                            <FormattedMessage
                                id="MSMarketplace.ContactChatCallToAction"
                                defaultMessage="livechat"
                            />
                        </ContactCallToAction>
                    </ContactItem>
                    <ContactItem>
                        <ContactIconWrapper>
                            <IconEmail color={colors.rockyGrey} />
                        </ContactIconWrapper>
                        <ContactDescription>
                            <FormattedMessage
                                id="MSMarketplace.ContactEmailDescription"
                                defaultMessage="Szybka pomoc (8:00-18:00)"
                            />
                        </ContactDescription>
                        <ContactCallToAction>
                            <FormattedMessage
                                id="MSMarketplace.ContactEmailCallToAction"
                                defaultMessage="Wiadomość e-mail"
                            />
                        </ContactCallToAction>
                    </ContactItem>
                    <ContactItem>
                        <ContactIconWrapper>
                            <IconPhone color={colors.rockyGrey} />
                        </ContactIconWrapper>
                        <ContactDescription>
                            <FormattedMessage
                                id="MSMarketplace.ContactPhoneDescription"
                                defaultMessage="Infolinia (8:00-18:00)"
                            />
                        </ContactDescription>
                        <ContactCallToAction>
                            <FormattedMessage
                                id="MSMarketplace.ContactPhoneCallToAction"
                                defaultMessage="+48 22 29 05 112"
                            />
                        </ContactCallToAction>
                    </ContactItem>
                </ContactWrapper>
                <ButtonsWrapper>
                    <Button.GhostRounded
                        label={
                            <FormattedMessage
                                id="MSMarketplace.Abort"
                                defaultMessage="Anuluj"
                            />
                        }
                        size={'LARGE'}
                        fullWidth={isMobile}
                        onClick={Cancel}
                    />
                    <Button.PrimaryRounded
                        label={
                            <FormattedMessage
                                id="MSMarketplace.TryAgain"
                                defaultMessage="Spróbuj ponownie"
                            />
                        }
                        size={'LARGE'}
                        fullWidth={isMobile}
                        onClick={Rety}
                    />
                </ButtonsWrapper>
            </StyledCard>
        </Wrapper>
    );
}

export default Error;
