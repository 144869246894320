import IconButton from '@material-ui/core/IconButton';
import {
  IconChevronLeft,
  IconChevronRight,
  IconDoubleChevronLeft,
  IconDoubleChevronRight,
} from '@commoncomponents/frontend-components/dist/components/Icons/Icons';
import { colors } from '@commoncomponents/frontend-components/dist/styled';

import { PaginationDiv } from './styled';

type TablePaginationActionsProps = {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: any, page: number) => void;
};

export const TablePaginationActions = (props: TablePaginationActionsProps) => {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick: React.MouseEventHandler<
    HTMLButtonElement
  > = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick: React.MouseEventHandler<HTMLButtonElement> = (
    event,
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick: React.MouseEventHandler<HTMLButtonElement> = (
    event,
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick: React.MouseEventHandler<
    HTMLButtonElement
  > = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <PaginationDiv>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <IconDoubleChevronLeft
          color={page === 0 ? colors.airlessPlatinum : colors.rockyGrey}
        />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <IconChevronLeft
          color={page === 0 ? colors.airlessPlatinum : colors.rockyGrey}
        />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <IconChevronRight
          color={
            page >= Math.ceil(count / rowsPerPage) - 1
              ? colors.airlessPlatinum
              : colors.rockyGrey
          }
        />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <IconDoubleChevronRight
          color={
            page >= Math.ceil(count / rowsPerPage) - 1
              ? colors.airlessPlatinum
              : colors.rockyGrey
          }
        />
      </IconButton>
    </PaginationDiv>
  );
};
