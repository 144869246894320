import {OrganizationUsersResponse} from './types';
import {helpers} from "autenti-components";

export const getOrganizationUser = async (): Promise<any> => {
    const url = `${process.env.REACT_APP_AUTENTI_API_URL}/accounts/self/organization`;
    let response;
    await fetch(url, {
        method: 'GET',
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            return response.text().then(text => {
                throw new Error(text)
            })
        })
        .then(json => {
            response = json;
        }).catch(error => {
            throw new Error(error);
        });
    return response;
};
