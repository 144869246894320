export const getResolveSubscription = async (): Promise<any> => {
    const url = `${process.env.REACT_APP_SAAS_API_URL}/app/api/Subscription/ResolveSubscription`;
    // const url = `${process.env.REACT_APP_SAAS_API_URL}/api/Subscription/ResolveSubscription`;
    let response;
    await fetch(`${url}?token=${localStorage.getItem('offer-token')}`, {
        method: 'GET',
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            return response.text().then(text => {
                throw new Error(text)
            })
        })
        .then(json => {
            response = json.data;
        }).catch(error => {
            throw new Error(error);
    });
    return response;
};
