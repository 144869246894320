import React, {useState, ReactNode, useEffect} from 'react';
import {helpers, ducks} from 'autenti-components';
import {bindActionCreators} from 'redux';
import {connect, useSelector} from 'react-redux';
import styled from 'styled-components';
import LogoutInfoBar from "../LoginFormLayout/LogoutInfoBar";
import Footer from "../LoginFormLayout/Footer";
import getIsSuperAdminSelector from "../../selectors/getIsSuperAdminSelector";
import {getOrganizationUser} from "../../api/organization/getOrganizationUser";
import WelcomeHeader from "../../scenes/Login/LoginForm/components/WelcomeHeader";


const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: #fff;
  padding: 16px;
  box-sizing: border-box;  
  z-index: 999;

  display: grid;
  grid-auto-columns: 1fr; 
  grid-template-columns: 1fr;
  grid-template-rows: 0.4fr 2.5fr;
  gap: 0px 0px;
  grid-template-areas:
    '.'
    '.';
  justify-items: center;

`;
const FormContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 24px;
  padding-bottom: 24px;
  max-width: 312px;

  @media (min-width: 768px) and (max-width: 1024px) {
    max-width: 476px;
  }

  @media (min-width: 1024px) {
    padding-top: 40px;
    padding-bottom: 40px;
    max-width: 368px;
  }
`;
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 312px;
  flex-wrap: wrap;
  align-self: flex-end;

  @media (min-width: 768px) and (max-width: 1024px) {
    max-width: 476px;
  }

  @media (min-width: 1024px) {
    max-width: 368px;
    margin-top: 80px;
  }

  .button-back {
    position: relative;
    left: -15px;
  }

  .lang-picker {
    position: relative;
    right: -15px;
  }
`;
const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 312px;
  flex-wrap: wrap;
  align-self: flex-end;

  @media (min-width: 768px) and (max-width: 1024px) {
    max-width: 476px;
  }

  @media (min-width: 1024px) {
    max-width: 368px;
  }

  .button-back {
    position: relative;
    left: -15px;
  }

  .lang-picker {
    position: relative;
    right: -15px;
  }
`;

type PropsType = {
    children: ReactNode;
    action?: string;
    languages?: { code: string }[];
    onBackAction?: () => void;
    LANG_ACTIONS: {
        FETCH_REQUEST: () => void;
        CHANGE_LANGUAGE: (a: string) => void;
        UPDATE_REQUEST: (a: any) => void;
    };
};
const FormWrapper = ({
                         children,
                         action,
                         languages,
                         onBackAction,
                         LANG_ACTIONS: {FETCH_REQUEST, CHANGE_LANGUAGE, UPDATE_REQUEST},
                     }: PropsType) => {
    const [organizationData, setorganizationData] = useState<any>(null);
    async function getUser() {
        const response = await getOrganizationUser();
        setorganizationData(response);
    }
    const isSuperAdmin = !!(organizationData && organizationData.role === 'SUPER_ADMIN');
    const hasLoginCookie = helpers.hasLoginCookie();
    const listOfActions = [
        'remind-password',
        'account-type',
        'private',
        'organization',
    ];
    const showBackButton = () => {
        if (action && listOfActions.some((a) => action.includes(a))) {
            return true;
        }
    };

    const defaultLanguageCode =
        (helpers.getNotLoggedLanguageCookie() &&
            helpers.getNotLoggedLanguageCookie().toUpperCase()) ||
        helpers.getLocaleLanguageOrDefault().toUpperCase();

    const [defaultLanguage, setDefaultLanguage] = useState(defaultLanguageCode);

    const setLanguage = (lang: string) => {
        CHANGE_LANGUAGE(lang.toLowerCase());
        helpers.setNotLoggedLanguageCookie(lang.toLowerCase());
        helpers.setLanguageCookie(lang.toLowerCase());
        setDefaultLanguage(lang.toUpperCase());
    };

    const onClick = () => {
        // onBackAction ? onBackAction() : history.back()
    };

    useEffect(() => {
        FETCH_REQUEST();
        getUser();
    }, [defaultLanguage]);

    return (
        <Wrapper>
            <HeaderContainer>
                <WelcomeHeader />
                <LogoutInfoBar hasLoginCookie={hasLoginCookie} isSuperAdmin={isSuperAdmin} />
            </HeaderContainer>
            <FormContent>
                {children}
            </FormContent>
            <Footer />
        </Wrapper>
    );
};
const mapStateToProps = (state: any) => ({
    languages: state.lang.get('languages'),
});

export const mapDispatchToProps = (dispatch: any) => ({
    LANG_ACTIONS: bindActionCreators(ducks.LANG_ACTIONS, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormWrapper);
