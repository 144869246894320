import ButtonIcon from '@commoncomponents/frontend-components/dist/components/ButtonIcon';
import { IconChevronDown } from '@commoncomponents/frontend-components/dist/components/Icons/Icons';
import { colors } from '@commoncomponents/frontend-components/dist/styled';

import { ExpandButtonWrapper } from './styled';

type ExpandButtonProps = {
  expanded?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

export const ExpandButton = (props: ExpandButtonProps) => {
  const { expanded, onClick } = props;
  return (
    <ExpandButtonWrapper expanded={expanded}>
      <ButtonIcon onClick={onClick} ariaLabel="expand-table-row">
        <IconChevronDown color={colors.rockyGrey} />
      </ButtonIcon>
    </ExpandButtonWrapper>
  );
};
