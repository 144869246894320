import styled from 'styled-components';
import CommonComponentsLoader from '@commoncomponents/frontend-components/dist/components/Loader';

const Loader = () => (
    <LoaderWrapper>
        <CommonComponentsLoader active={true} background={false} />
    </LoaderWrapper>
);

const LoaderWrapper = styled.div({
    padding: '40px',
});

export default Loader;
