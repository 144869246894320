import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import Carousel from '../../components/Carousel';
import LoginForm from './LoginForm';
import SceneWrapper from "../../components/SceneWrapper";
import FormWrapper from "../../components/FormWrapper";

const StyledWrapper = styled.div`
  width: 100%;
  overflow: auto;
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 1024px) {
    grid-template-columns: 5fr 7fr;
  }
`;

const LoginComponent = ({}) => {
  useEffect(() => {
    if (window.location?.search) {
      const searchParams = new URLSearchParams(window.location?.search);
    }
  }, []);
  return (
    <SceneWrapper fullWidth={true}>
      <Helmet>
        <link rel="canonical" href="https://account.autenti.com/login" />
      </Helmet>
      <StyledWrapper>
        <FormWrapper
          action={''}
          onBackAction={() => test}
        >
            <LoginForm/>
        </FormWrapper>
        <Carousel />
      </StyledWrapper>
    </SceneWrapper>
  );
};

export const Login = injectIntl(LoginComponent);

const mapStateToProps = (state: any) => ({
  // screenId: state.loginApi.get('screenId'),
});

export const mapDispatchToProps = (dispatch: any) => ({
  // LANG_ACTIONS: bindActionCreators(ducks.LANG_ACTIONS, dispatch),
  // SNACKBAR_ACTIONS: bindActionCreators(ducks.SNACKBAR_ACTIONS, dispatch),
  // LOGIN_API_ACTIONS: bindActionCreators(loginApiActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
