import styled from 'styled-components';
import { colors } from '@commoncomponents/frontend-components/dist/styled';
import { Card } from '../../components/Card';

const PlansUsersDiv = styled.div`
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto;
    gap: 24px;

    h2 {
        margin: 0;
    }
`;

const PlansUsersHeaderDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1024px) {
        flex-direction: column;
        gap: 24px;
        align-items: flex-start;
    }
`;

const PaymentsUsersDiv = styled.div`
    position: relative;
    flex: 1;
`;

const SegmentedWrapperDiv = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;

    @media (max-width: 1024px) {
        position: relative;
        margin-bottom: 16px;
    }
`;

const ButtonsDiv = styled.div`
    display: flex;
    gap: 16px;

    @media (max-width: 1024px) {
        z-index: 1;
        position: fixed;
        bottom: 70px;
        right: 16px;
    }
`;

const Wrapper = styled.div({
    margin: '3.5rem auto 0',
    overflow: 'auto',
    '@media(max-width: 1024px)': {
        margin: 0,
        padding: '1rem',
    },
});

const StyledCard = styled(Card)({
    width: 'max(688px, 50vw)',
    padding: '3rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2rem',
    '@media(max-width: 1024px)': {
        margin: 0,
        padding: '2rem',
        width: '100%',
    },
});

const ButtonsWrapper = styled.div({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1.5rem',
    '@media(max-width: 768px)': {
        flexWrap: 'wrap-reverse',
        gap: '1rem',
    },
});

const ContactWrapper = styled.div({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    gap: '.5rem',
    '@media(max-width: 768px)': {
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'start',
        width: '100%',
    },
});

const ContactItem = styled.div({
    padding: '.5rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '4px',
    '@media(max-width: 768px)': {
        background: colors.rousingPearl,
        borderRadius: '.75rem',
        padding: '1rem',
    },
});

const ContactDescription = styled.span({
    color: colors.rockyGrey,
    fontSize: '.75rem',
    lineHeight: '18px',
    textAlign: 'center',
});

const ContactCallToAction = styled.a({
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '22px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    letterSpacing: '0.04px',
    color: colors.floppyAqua,
    textDecoration: 'none',
    cursor: 'pointer',
});

const ContactIconWrapper = styled.div({
    marginBottom: '15px',
    '@media(max-width: 768px)': {
        marginBottom: '13px',
    },
});

export {
    PlansUsersDiv,
    PlansUsersHeaderDiv,
    PaymentsUsersDiv,
    SegmentedWrapperDiv,
    ButtonsDiv,
    Wrapper,
    StyledCard,
    ButtonsWrapper,
    ContactWrapper,
    ContactItem,
    ContactDescription,
    ContactCallToAction,
    ContactIconWrapper,
};
