import styled, { css } from 'styled-components';
import CommonDivider from '@commoncomponents/frontend-components/dist/components/Divider';

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Divider = styled(CommonDivider)`
  @media (min-width: 1024px) {
    width: 132px;
  }
`;

export const Footer = styled.footer`
  padding-top: 24px;
  padding-bottom: 24px;
`;

export const CreateLinkDiv = styled.div`
  display: inline;
  width: 100%;

  a {
    margin-left: 8px;
  }

  @media (max-width: 1024px) {
    text-align: center;
    display: block;
    .question {
      display: none;
    }
    a {
      margin-left: 0px;
    }
  }
`;

export const LegacyLoginDiv = styled.div`
  margin-top: 16px;

  @media (max-width: 1024px) {
    display: none;
  }
`;
