import styled from 'styled-components/macro';
import { colors } from '@commoncomponents/frontend-components/dist/styled';

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const AvatarCircleDiv = styled.div<{ size: number }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.stressfulSnow};
  flex-shrink: 0;
`;

export const AvatarSpan = styled.span<{ size: number }>`
  color: ${colors.snowy};
  text-transform: uppercase;
  font-size: ${(props) => props.size / 2 - 4}px;
  line-height: 22px;
  font-weight: 700;
  font-family: 'DM Sans', sans-serif;
  text-align: center;
`;

export const AvatarTextsDiv = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const NameSurnameSpan = styled.span<{ bold?: boolean }>`
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: ${colors.lameJet};
  ${(props) => props.bold && 'font-weight: 700'}
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const EmailSpan = styled.span`
  font-size: 12px;
  line-height: 18px;
  color: ${colors.rockyGrey};
  overflow: hidden;
  text-overflow: ellipsis;
`;
