import styled, { css } from 'styled-components/macro';
import MuiTableHead from '@material-ui/core/TableHead';
import MuiTableRow from '@material-ui/core/TableRow';
import MuiTableFooter from '@material-ui/core/TableFooter';
import MuiTableContainer from '@material-ui/core/TableContainer';
import MuiTablePagination from '@material-ui/core/TablePagination';

import { colors } from '@commoncomponents/frontend-components/dist/styled';

export const TableContainer = styled(MuiTableContainer).withConfig<{
  extraCss?: ReturnType<typeof css>;
}>({ shouldForwardProp: (prop) => prop !== 'extraCss' })`
  background-color: transparent;
  box-shadow: none;

  ${(props) => props.extraCss}
`;

export const TableHead = styled(MuiTableHead)`
  th {
    border-bottom: none;
    span {
      color: ${colors.realCobalt};
      font-weight: 700;
    }
  }
`;

export const StyledTableRow = styled(MuiTableRow)`
  & > td {
    border-bottom: none;
  }
`;

export const RecordTableRow = styled(StyledTableRow)`
  & > td {
    background-color: ${colors.snowy};
  }

  & > td:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  & > td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

export const ExpandedRecordTableRow = styled(RecordTableRow)`
  @media (min-width: 1024px) {
    transform: scaleX(0.97);
  }
`;

export const TableRowSpacer = styled(MuiTableRow)<{
  size: 'small' | 'medium' | 'large';
}>`
  ${({ size }) =>
    size === 'small' &&
    `
    height: 8px;
  `}

  ${({ size }) =>
    size === 'medium' &&
    `
    height: 16px;
  `}

  ${({ size }) =>
    size === 'large' &&
    `
      height: 24px;
    `}
`;

export const ExpandButtonWrapper = styled.div<{ expanded?: boolean }>`
  & > button {
    padding: 6px;
    transform: rotate(0deg);
    transition: transform 0.2s ease-out;
    ${({ expanded }) => expanded && 'transform: rotate(180deg);'}
  }

  & svg path:first-of-type {
    fill: ${({ expanded }) =>
      expanded ? `${colors.floppyAqua}` : `${colors.rockyGrey}`};
  }
`;

export const PaginationTableFooter = styled(MuiTableFooter)`
  p,
  select {
    font-family: 'DM Sans', sans-serif !important;
    color: ${colors.realCobalt};
  }
`;

export const PaginationDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const LoaderWrapper = styled.div`
  padding: 40px;
`;

export const TablePagination = styled(MuiTablePagination).withConfig<{
  isMobile?: boolean;
}>({ shouldForwardProp: (prop) => prop !== 'isMobile' })`
  & > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: ${(props) => (props.isMobile ? 'center' : 'flex-end')};
  }
`;
