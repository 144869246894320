import { FormattedMessage } from 'react-intl';
import * as Button from '@commoncomponents/frontend-components/dist/components/Buttons';
import * as Text from '@commoncomponents/frontend-components/dist/components/Text';
import { Wrapper, StyledCard, ButtonsWrapper } from './styled';
import { useIsMobile } from './utils/useIsMobile';
import {Utils} from "autenti-components";

function ConfettiSVG() {
    return (
        <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M99.0875 50C99.0875 77.6142 76.906 100 49.5437 100C22.1815 100 0 77.6142 0 50C0 22.3858 22.1815 0 49.5437 0C76.906 0 99.0875 22.3858 99.0875 50Z"
                fill="#F7F9FB"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M57.2757 41.0511C45.5627 29.1466 43.4248 20.3124 43.4248 20.3124L37.0079 35.8285C41.9503 47.9394 51.2039 57.6777 62.8527 63.137L78.9606 56.4292C78.9606 56.4292 68.9887 52.9556 57.2757 41.0511ZM58.2082 65.071C47.7871 60.1227 39.506 51.3644 35.0771 40.4973L28.0104 57.5847C31.7124 63.2057 36.4473 68.0477 41.9461 71.8429L58.2082 65.071ZM20.3298 76.1566L26.1514 62.0798C29.2415 66.5922 33.0804 70.5246 37.4888 73.699L23.5807 79.4907C21.5192 80.3491 19.4665 78.2439 20.3298 76.1566Z"
                fill="url(#paint0_linear_324_9237)"
            />
            <path
                d="M79.932 56.495C77.7513 58.6957 67.8622 52.2836 57.8439 42.1731C47.8257 32.0625 41.472 22.0823 43.6527 19.8816C45.8333 17.6809 55.7225 24.093 65.7407 34.2035C75.759 44.3141 82.1126 54.2943 79.932 56.495Z"
                fill="#004DB3"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M80.8225 26.212C80.8977 25.5135 80.3975 24.8857 79.7053 24.8099C78.3546 24.6618 77.2771 23.5959 76.9284 22.2784C76.5977 21.0286 76.9533 19.6747 78.407 18.8447C80.5368 17.6286 82.2695 18.3368 84.6512 19.377C84.6778 19.3886 84.7046 19.4003 84.7314 19.412C86.9979 20.4024 89.8676 21.6563 93.2204 19.7418C95.7328 18.3074 96.6852 15.6557 96.4205 13.3382C96.1591 11.0483 94.6361 8.76532 91.9383 8.46965C91.2462 8.39379 90.6241 8.89857 90.549 9.59712C90.4738 10.2957 90.974 10.9234 91.6661 10.9993C92.8613 11.1303 93.7469 12.1497 93.9158 13.6295C94.0816 15.0815 93.4862 16.6665 91.9789 17.5272C89.7869 18.7788 88.0156 18.0738 85.6526 17.0418C85.604 17.0206 85.5552 16.9992 85.5061 16.9777C83.2685 15.9989 80.4388 14.761 77.1654 16.6301C74.5994 18.0952 73.9001 20.6967 74.4925 22.9351C75.067 25.1057 76.891 27.0609 79.4331 27.3395C80.1253 27.4154 80.7474 26.9106 80.8225 26.212Z"
                fill="#1872E7"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M85.6552 51.0953C83.2782 50.5444 80.2724 49.8477 77.3913 52.2875C76.858 52.7391 76.7884 53.5416 77.236 54.0799C77.6835 54.6181 78.4787 54.6883 79.012 54.2367C80.8866 52.6492 82.7181 53.0269 85.2478 53.6117C85.2761 53.6182 85.3045 53.6248 85.3329 53.6313C87.7403 54.1882 90.7884 54.8934 93.7394 52.3944C94.2728 51.9427 94.3423 51.1402 93.8948 50.602C93.4473 50.0637 92.6521 49.9935 92.1188 50.4452C90.1895 52.0789 88.3205 51.7115 85.8107 51.1313C85.7592 51.1194 85.7073 51.1074 85.6552 51.0953Z"
                fill="#1872E7"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M50.4952 25.8746C51.1673 26.058 51.8595 25.6568 52.0412 24.9785C52.1182 24.6912 52.2013 24.3891 52.288 24.074C52.8362 22.0816 53.5275 19.5696 53.706 17.0071C53.9153 14.0011 53.4375 10.7523 51.1331 7.98083C50.6856 7.44257 49.8904 7.37236 49.3571 7.82402C48.8237 8.27567 48.7542 9.07816 49.2017 9.61642C50.9409 11.7082 51.3731 14.2122 51.1909 16.8287C51.0317 19.1137 50.4177 21.3523 49.8673 23.359C49.7782 23.6838 49.6908 24.0025 49.6073 24.3143C49.4256 24.9926 49.8231 25.6912 50.4952 25.8746Z"
                fill="#1872E7"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M64.6303 23.3538C65.0976 23.8747 65.8948 23.9148 66.411 23.4433C67.2329 22.6924 67.5585 22.395 67.8841 22.0977C68.2978 21.7197 68.7115 21.3418 70.144 20.0332C70.6602 19.5617 70.6999 18.7571 70.2327 18.2362C69.7655 17.7152 68.9682 17.6752 68.4521 18.1467C67.0195 19.4553 66.6058 19.8332 66.1921 20.2112C65.8666 20.5085 65.541 20.8059 64.719 21.5567C64.2028 22.0282 64.1631 22.8328 64.6303 23.3538Z"
                fill="#1872E7"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M84.1465 38.3339C84.6137 38.8548 85.4109 38.8949 85.9271 38.4234C86.7491 37.6725 87.0746 37.3751 87.4002 37.0778C87.8139 36.6998 88.2276 36.3219 89.6601 35.0133C90.1763 34.5418 90.216 33.7372 89.7488 33.2163C89.2816 32.6953 88.4844 32.6553 87.9682 33.1268C86.5356 34.4354 86.1219 34.8133 85.7082 35.1913C85.3827 35.4886 85.0571 35.786 84.2351 36.5368C83.7189 37.0083 83.6792 37.8129 84.1465 38.3339Z"
                fill="#1872E7"
            />
            <path
                d="M88.0946 45.2267C87.9758 46.2031 87.0952 46.8974 86.1277 46.7775C85.1603 46.6576 84.4723 45.7689 84.5911 44.7926C84.7099 43.8162 85.5904 43.1218 86.5579 43.2417C87.5254 43.3616 88.2134 44.2503 88.0946 45.2267Z"
                fill="#1872E7"
            />
            <path
                d="M71.8085 48.3295C71.6897 49.3059 70.8091 50.0002 69.8416 49.8803C68.8741 49.7604 68.1861 48.8717 68.3049 47.8953C68.4237 46.919 69.3043 46.2246 70.2718 46.3445C71.2393 46.4644 71.9272 47.3531 71.8085 48.3295Z"
                fill="#1872E7"
            />
            <path
                d="M73.4724 34.7592C73.3876 35.4566 72.7586 35.9525 72.0675 35.8669C71.3765 35.7813 70.885 35.1465 70.9699 34.4491C71.0548 33.7516 71.6837 33.2557 72.3748 33.3413C73.0658 33.427 73.5573 34.0617 73.4724 34.7592Z"
                fill="#1872E7"
            />
            <path
                d="M62.3079 34.6575C62.223 35.3549 61.594 35.8508 60.903 35.7652C60.2119 35.6796 59.7205 35.0448 59.8053 34.3474C59.8902 33.65 60.5192 33.154 61.2102 33.2396C61.9013 33.3253 62.3927 33.9601 62.3079 34.6575Z"
                fill="#1872E7"
            />
            <path
                d="M46.8913 22.1539C46.8234 22.7119 46.3202 23.1086 45.7674 23.0401C45.2146 22.9716 44.8214 22.4638 44.8893 21.9059C44.9572 21.3479 45.4604 20.9512 46.0132 21.0197C46.5661 21.0882 46.9592 21.596 46.8913 22.1539Z"
                fill="#1872E7"
            />
            <path
                d="M59.2211 22.7023C59.1023 23.6787 58.2217 24.373 57.2542 24.2531C56.2867 24.1332 55.5987 23.2445 55.7175 22.2681C55.8363 21.2918 56.7169 20.5974 57.6844 20.7173C58.6518 20.8372 59.3398 21.7259 59.2211 22.7023Z"
                fill="#1872E7"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_324_9237"
                    x1="10.0158"
                    y1="68.94"
                    x2="74.2943"
                    y2="65.9475"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#1872E7" />
                    <stop offset="1" stop-color="#4D9AFF" />
                </linearGradient>
            </defs>
        </svg>
    );
}

function Confirmation() {

    const isMobile = useIsMobile();
    return (
        <Wrapper>
                <StyledCard>
                    <ConfettiSVG />
                    <Text.H3>
                        <FormattedMessage
                            id="MSMarketplace.Congratulations"
                            defaultMessage="Gratulacje, aktywowaliśmy Twoje plany!"
                        />
                    </Text.H3>
                    <ButtonsWrapper>
                        {/*<Button.SecondaryRounded*/}
                        {/*    label={*/}
                        {/*        <FormattedMessage*/}
                        {/*            id="MSMarketplace.GoBackToMarketplace"*/}
                        {/*            defaultMessage="Wróć do sklepu"*/}
                        {/*        />*/}
                        {/*    }*/}
                        {/*    size={'MEDIUM'}*/}
                        {/*    fullWidth={isMobile}*/}
                        {/*/>*/}
                        <Button.PrimaryRounded
                            label={
                                <FormattedMessage
                                    id="MSMarketplace.GoToAutenti"
                                    defaultMessage="Przejdź do Autenti"
                                />
                            }
                            size={'MEDIUM'}
                            fullWidth={isMobile}
                            onClick={() => {window.location.replace(`${Utils.getUrlTo('docs')}`)}}
                        />
                    </ButtonsWrapper>
                </StyledCard>
        </Wrapper>
    );
}

export default Confirmation;
