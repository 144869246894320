import React from 'react';
import styled from 'styled-components';

import { Header as HeaderComponent } from 'autenti-components';

const Styled = styled.div`
  .Header {
    padding: 0;
    background: #fff;
    position: fixed;
    width: 100%;
  }

  .Header .account-content-wrapper .header-autenti-logo {
    position: absolute;
    left: 15px;
  }

  .account-content-wrapper {
    max-width: 1140px;
    margin: 0 auto;
    width: 100%;
    position: relative;
  }

  .Header .navbar-header {
    justify-content: center;
    align-items: center;
    flex-direction: row;
    text-align: center;
  }

  .account-content,
  .header-text-wrapper {
    display: none;
  }
`;

const Header = () => {
  const headerProps = {
    wrapperTextClassName: 'header-text-wrapper hidden-sm-down',
    hideTextOnMobile: false,
    height: '56px',
  };
  return (
    <Styled>
      <HeaderComponent {...headerProps} />
    </Styled>
  );
};

export default Header;
