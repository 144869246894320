import React from 'react';
import './App.css';
import {GTM, Styled} from "autenti-components";
import {Provider} from "react-redux";
import {ThemeProvider} from "styled-components";
import Scenes from "./scenes";
import {store} from "./app/store";
import {FeatureFlagsProvider} from '@autenti/feature-flags';
import IntlWrapper from "./intlWrapper";


function App() {

    return (

        <Provider store={store}>
            <GTM />
            <ThemeProvider theme={Styled.SSTheme}>
                <FeatureFlagsProvider>
                    <IntlWrapper>
                    {/*    <GTM/>*/}
                        <Scenes />
                    </IntlWrapper>
                </FeatureFlagsProvider>
            </ThemeProvider>
        </Provider>
    );
}

export default App;
