import { combineReducers } from 'redux';
import { ducks } from 'autenti-components';
import { Map } from 'immutable';
import organizationUsers from './organizationUsers';

const reducers = {
  organizationUsers,
  naturalUser: ducks.naturalUser,
  organizationUser: ducks.organizationUser,
  globalError: ducks.globalError,
  responsive: ducks.responsiveReducer,
  navigation: ducks.navigationReducer,
  account: ducks.accountReducer,
  lang: ducks.langReducer,
  phoneCountry: ducks.phoneCountryReducer,
  planSubscriptions: ducks.planSubscriptionsReducer,
  organizationLogo: ducks.organizationLogoReducer,
  logout: ducks.logoutReducer,
  certificates: ducks.certificatesReducer,
  tags: ducks.tagsReducer,
  globalSnackbar: ducks.globalSnackbarReducer,
  semanticIdentifiers: ducks.semanticIdentifiersReducer,
  messageTemplates: ducks.messageTemplatesReducer,
  organizationPayment: ducks.organizationPaymentReducer,
  organizationActiveUsers: ducks.organizationActiveUsersReducer,
  entitlements: ducks.entitlementsReducer,
  organizationActivationCodes: ducks.organizationActivationCodesReducer,
};

export type State = { [K in keyof typeof reducers]: Map<string, any> };

const rootReducer = combineReducers(reducers) as any;

export default rootReducer;
