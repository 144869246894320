import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { colors } from '@commoncomponents/frontend-components/dist/styled';
import * as Stepper from '@commoncomponents/frontend-components/dist/components/Stepper/';
import {useLocation} from "react-router-dom";

const Wrapper = styled.div({
    background: colors.snowy,
});

function StepperWrapper() {
    const { formatMessage } = useIntl();
    const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
    const [pathname, setPathname] = useState(window.location.pathname);
    const location = useLocation();
    const handlePopstateEvent = () => {
        const currentPath = window.location.pathname;
        setPathname(currentPath);
    };
    useEffect(() => {
        window.addEventListener('popstate', handlePopstateEvent);
        return () => {
            window.removeEventListener('popstate', handlePopstateEvent);
        };
    }, [pathname]);
    
    useEffect(() => {
        switch (location.pathname) {
            case '/':
                setActiveStepIndex(0);
                break;

            case '/step2':
                setActiveStepIndex(1);
                break;

            case '/step3':
                setActiveStepIndex(2);
                break;
            case '/error':
                setActiveStepIndex(1);
                break;
            default:
                setActiveStepIndex(0);
                break;
        }
    }, [location]);

    return (
        <Wrapper>
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <Stepper.default
                activeStepIndex={activeStepIndex}
                mobileBreakpointPx={768}
            >
                {formatMessage({
                    id: 'MSMarketplace.Steps.LoginSignup',
                    defaultMessage: 'Logowanie/rejestracja',
                })}
                {formatMessage({
                    id: 'MSMarketplace.Steps.PlanAssignment',
                    defaultMessage: 'Przypisanie planów',
                })}
                {formatMessage({
                    id: 'MSMarketplace.Steps.Confirmation',
                    defaultMessage: 'Potwierdzenie',
                })}
            </Stepper.default>
        </Wrapper>
    );
}

export default StepperWrapper;
