import styled from 'styled-components/macro';
import { colors } from '@commoncomponents/frontend-components/dist/styled';

export const Card = styled.section<{ maxWidth?: number }>`
  background-color: ${colors.snowy};
  border-radius: 8px;
  padding: 24px;
  height: fit-content;
  width: 100%;

  @media (max-width: 1024px) {
    padding: 16px;
  }

  h1,
  h2,
  h3,
  h4 {
    margin: 0;
  }

  ${(props) => props.maxWidth && ` max-width ${props.maxWidth}px`}
`;

export const CardHeader = styled.span`
  display: flex;
  justify-content: space-between;
  gap: 4px;
  margin-bottom: 16px;

  @media (max-width: 1024px) {
    margin-bottom: 8px;
  }
`;
