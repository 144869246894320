import React, { useEffect, useState } from 'react';
import { css } from 'styled-components/macro';
import MuiTable from '@material-ui/core/Table';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableBody from '@material-ui/core/TableBody';


import { TableRow } from './TableRow';
import { Pagination, PaginationProps } from './Pagination';
import { NoResults } from './NoResults';
import {
  TableContainer,
  TableHead,
  LoaderWrapper,
  StyledTableRow,
} from './styled';
import {Loader} from "../Loader";

type TableProps<T> = React.ComponentProps<typeof MuiTable> & {
  HeaderCells?: () => JSX.Element;
  renderRow: (item: T) => JSX.Element;
  renderMobileRow?: (item: T) => JSX.Element;
  renderExpandableRow?: (item: T) => JSX.Element;
  items: Array<T> | undefined;
  isMobile?: boolean;
  pagination?: PaginationProps;
  isProcessing?: boolean;
  NoResultComponent?: () => JSX.Element;
  noResultText?: string | JSX.Element;
  maxHeight?: number;
  onRowClick?: (event: React.MouseEvent<HTMLTableRowElement>, item: T) => void;
  extraCss?: ReturnType<typeof css>;
  dataTestId?: string;
};

export const Table = <T,>(props: TableProps<T>) => {
  const {
    HeaderCells,
    renderRow,
    renderMobileRow,
    NoResultComponent,
    noResultText,
    items,
    isMobile,
    pagination,
    isProcessing,
    maxHeight,
    onRowClick,
    extraCss,
    renderExpandableRow,
    dataTestId,
    ...muiTableProps
  } = props;

  const [page, setPage] = useState<number>(props.pagination?.page || 0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    props.pagination?.rowsPerPage || 10,
  );

  const handleChangePage: PaginationProps['onPageChange'] = (
    event,
    newPage: number,
  ) => {
    setPage(newPage);
    props.pagination?.onPageChange(event, newPage);
  };

  const handleChangeRowsPerPage: PaginationProps['onRowsPerPageChange'] = (
    event,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    if (props.pagination?.onRowsPerPageChange)
      props.pagination.onRowsPerPageChange(event);
  };

  const handleOnRowClicked = (
    event: React.MouseEvent<HTMLTableRowElement>,
    item: T,
  ) => {
    if (isMobile && onRowClick) onRowClick(event, item);
  };

  useEffect(() => {
    setPage(props.pagination?.page || 0);
  }, [props.pagination?.page]);

  return (
    <TableContainer
      extraCss={extraCss}
      {...(dataTestId ? { 'data-testid': dataTestId } : {})}
    >
      <MuiTable {...muiTableProps}>
        {!isMobile &&
          HeaderCells &&
          !isProcessing &&
          items &&
          items?.length > 0 && (
            <TableHead>
              <StyledTableRow>
                <HeaderCells />
              </StyledTableRow>
            </TableHead>
          )}
        {isProcessing && (
          <MuiTableBody>
            <StyledTableRow>
              <MuiTableCell colSpan={100}>
                <LoaderWrapper data-testid="table-loader">
                  <Loader />
                </LoaderWrapper>
              </MuiTableCell>
            </StyledTableRow>
          </MuiTableBody>
        )}
        {!isProcessing && (
          <MuiTableBody>
            {items?.length ? (
              items.map((item: T, index) => (
                <TableRow
                  key={`table-body-row__${index}`}
                  item={item}
                  isMobile={isMobile}
                  renderRow={renderRow}
                  renderMobileRow={renderMobileRow}
                  renderExpandableRow={renderExpandableRow}
                  onClick={handleOnRowClicked}
                />
              ))
            ) : (
              <StyledTableRow>
                <MuiTableCell
                  colSpan={100}
                  align="center"
                  data-testid="table-empty-result"
                  className="table-no-results"
                >
                  {NoResultComponent ? (
                    <NoResultComponent />
                  ) : (
                    <NoResults text={noResultText} />
                  )}
                </MuiTableCell>
              </StyledTableRow>
            )}
          </MuiTableBody>
        )}
        {pagination && !isProcessing && items && items?.length > 0 && (
          <Pagination
            {...pagination}
            isMobile={isMobile}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </MuiTable>
    </TableContainer>
  );
};
