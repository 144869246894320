import {
    createContext,
    Dispatch,
    SetStateAction,
    useCallback,
    useContext,
    useState,
} from 'react';

import { getOrganizationId } from '../../../utils/getOrganizationId';
import { getOrganizationUsers } from '../../../api/organization/getOrganizationUsers';
import { convertOrganizationUserResponse } from '../utils/convertOrganizationUsersResponse';

type UsersContext = [any, Dispatch<SetStateAction<any>>];

const Context = createContext<UsersContext | undefined>(undefined);

export function assertContextIsDefined<T>(
    context: T | undefined,
    name?: string
): asserts context is T {
    if (!context) throw new Error(`Context ${name} is not defined`);
}

interface Props {
    children: React.ReactNode;
}

export const UsersContextProvider: React.FC<Props> = (props) => {
    const value = useState<any>();
    return <Context.Provider value={value}>{props.children}</Context.Provider>;
};

export const useUsersContext = () => {
    const context = useContext(Context);
    assertContextIsDefined<UsersContext>(context);
    const [state, setState] = context;

    const fetchUsers = useCallback(
        async (options: {
            size: number;
            page: number;
            count: number;
            search: string;
        }) => {
            const { size, page, count, search } = options;
            setState({ type: 'LOADING', page, size, count, search });
            try {
                const response = await getOrganizationUsers({
                    organizationId: getOrganizationId(),
                    size,
                    page,
                    searchText: search,
                });
                const userDetails = convertOrganizationUserResponse(
                    response.content
                );
                setState({
                    type: 'LOADING',
                    page: page,
                    size: size,
                    users: userDetails,
                    count: response.totalElements,
                    search: search,
                });
            } catch (err: unknown) {
                setState({
                    type: 'LOADING',
                    error: err,
                    page: page,
                    size: size,
                    search,
                });
            }
        },
        [setState]
    );

    const fetchMoreUsers = useCallback(
        async (options: {
            size: number;
            page: number;
            count: number;
            search: string;
        }) => {
            const { size, page, count, search } = options;
            setState({ type: 'LOADING', page, size, count, search });

            try {
                const response = await getOrganizationUsers({
                    organizationId: getOrganizationId(),
                    size,
                    page: 0,
                    searchText: search,
                });

                const responseNext = await getOrganizationUsers({
                    organizationId: getOrganizationId(),
                    size,
                    page: page + 1,
                    searchText: search,
                });

                const userDetails = convertOrganizationUserResponse([
                    ...response.content,
                    ...responseNext.content,
                ]);
                setState({
                    type: 'LOADING',
                    page: page,
                    size: size,
                    users: userDetails,
                    count: response.totalElements,
                    search: search,
                });
            } catch (err: unknown) {
                setState({
                    type: 'LOADING',
                    error: err,
                    page: page,
                    size: size,
                    search,
                });
            }
        },
        [setState]
    );

    const findUser = useCallback(
        (userId: number) => {
            return state?.users.find((x) => x.id === userId);
        },
        [state]
    );

    return { state, fetchUsers, fetchMoreUsers };
};
