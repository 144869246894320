import React, { Fragment, ReactNode, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import {LoaderWrapperStatement, helpers, ducks} from 'autenti-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loader } from "./components/Loader";

type PropsType = {
    children: ReactNode;
    lang: string;
    LANG_ACTIONS: {
        CHANGE_LANGUAGE: (lang: string) => void;
        FETCH_TRANSLATED_LANG_REQUEST: (lang: string) => void;
    };
};

const IntlWrapper = ({
                         children, lang,
                         LANG_ACTIONS: { CHANGE_LANGUAGE, FETCH_TRANSLATED_LANG_REQUEST },
                     }: PropsType): JSX.Element => {
    const [messages, setMessages] = useState({});

    useEffect(() => {
        if (lang) {
            import(`/public/translations/${lang}.json`)
                .then((data) => {
                    setMessages(data);
                    FETCH_TRANSLATED_LANG_REQUEST(lang);
                })
                .catch((error) => console.error("Could not load translations", error));
        }
    }, [lang, FETCH_TRANSLATED_LANG_REQUEST]);

    useEffect(() => {
        // if (
        //     window.location.pathname === '/register' ||
        //     window.location.pathname === '/login'
        // ) {
        //     const url = new URLSearchParams(window.location.search);
        //     let urlParamLang: string | undefined;
        //
        //     if (url.has('lang')) {
        //         urlParamLang = url.get('lang')?.toLowerCase();
        //     } else if (url.has('return')) {
        //         const returnUrlQuery = url.get('return')?.split('?')[1];
        //         const returnUrlSearchParams = new URLSearchParams(returnUrlQuery);
        //         urlParamLang = returnUrlSearchParams.get('lang')?.toLowerCase();
        //     }
        //
        //     if (urlParamLang && helpers.isAllowedLanguage(urlParamLang)) {
        //         helpers.setLanguageCookie(urlParamLang);
        //         helpers.setNotLoggedLanguageCookie(urlParamLang);
        //     }
        // }
        const storageLang = helpers.hasLoginCookie()
            ? helpers.getLanguageCookie()
            : helpers.getNotLoggedLanguageCookie();

        CHANGE_LANGUAGE(
            storageLang && storageLang !== 'default'
                ? storageLang
                : helpers.getLocaleLanguageOrDefault(),
        );

    }, []);

    return (
        <Fragment>
            {lang ? (
                <IntlProvider locale={lang} messages={messages} defaultLocale="en">
                    {children}
                </IntlProvider>
            ) : (
                <LoaderWrapperStatement loaded={false}>
                    <span />
                </LoaderWrapperStatement>
            )}
        </Fragment>
    );
};

const mapStateToProps = (state: any) => ({
    lang: state.lang.get('lang'),
});

export const mapDispatchToProps = (dispatch: any) => ({
    LANG_ACTIONS: bindActionCreators(ducks.LANG_ACTIONS, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(IntlWrapper);
